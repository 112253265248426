import { Injectable, Optional, Inject, Injector, ComponentFactoryResolver, Type, ApplicationRef, EmbeddedViewRef } from "@angular/core";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { NotificationComponent } from "@shared/notification/notification.component";

type OriginShowNotification = OverlayComponent["showNotification"];

@Injectable({
	providedIn: "root",
})
export class NotificationService {
	private overlayComponent?: OverlayComponent;
	private notificationInstance?: NotificationComponent;

	constructor(
		componentFactoryResolver: ComponentFactoryResolver,
		@Inject("OverlayComponentType") @Optional() private overlayComponentType: Type<OverlayComponent>,
		applicationRef: ApplicationRef,
		injector: Injector
	) {
		if (this.overlayComponentType) {
			const componentFactory = componentFactoryResolver.resolveComponentFactory(this.overlayComponentType);
			const newComponentInstance = componentFactory.create(injector);
			this.overlayComponent = newComponentInstance.instance;
			applicationRef.attachView(newComponentInstance.hostView);
			const element = (newComponentInstance.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			document.body.appendChild(element);
		}
	}

	public showNotification(...args: Parameters<OriginShowNotification>): NotificationComponent {
		if (this.notificationInstance) {
			this.notificationInstance.close$$.next();
		}
		this.notificationInstance = this.overlayComponent?.showNotification(...args);
		return this.notificationInstance;
	}

	public closeNotification(): void {
		if (this.notificationInstance) {
			this.notificationInstance.close$$.next();
		}
	}
}
