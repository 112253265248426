import { DOCUMENT } from "@angular/common";
import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, Inject, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-hint",
	templateUrl: "./hint.component.html",
	styleUrls: ["./hint.component.scss"]
})
export class HintComponent implements AfterViewChecked {
	@Input() public text: string;
	@Input() public tooltipTemplateRef: TemplateRef<HTMLElement>;
	@ViewChild("section", { read: ElementRef, static: false }) public section?: ElementRef<HTMLElement>;
	public expanded = false;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document
	) { }

	public ngAfterViewChecked() {
		if (this.document && this.section && this.section.nativeElement) {
			const elem = this.section.nativeElement;
			const rect = elem.getBoundingClientRect();

			// horizontal
			if (rect.right > document.body.offsetWidth) {
				// check if left space larger than right space
				const right = document.body.offsetWidth - rect.left;
				elem.style.left = "auto";
				elem.style.right = "25px";
				const left = elem.getBoundingClientRect().right;
				if (left > right) {
					// elem.style.width = left + "px";
				} else {
					elem.style.right = null;
					elem.style.left = "0px";
					elem.style.width = right + "px";
				}
			}

			// vertical
			if (rect.top < 0) {
				// check if bottom space larger than top space
				const top = rect.bottom;
				elem.style.bottom = "auto";
				elem.style.top = "0px";
				const bottom = document.body.offsetHeight - elem.getBoundingClientRect().top;
				elem.style.maxHeight = bottom + "px";
				if (bottom < top) { // revert
					elem.style.top = null;
					elem.style.bottom = "0px";
					elem.style.maxHeight = top + "px";
				}
			}
			// -10 because have padding: 5px
			if (rect.width - 10 > 370) {
				elem.style.width = "370px";
				elem.style.whiteSpace = "normal";
				if (elem.getBoundingClientRect().height > rect.height) {
					elem.style.bottom = `-${rect.height / 2}px`;
				}
			}
		}
	}

	public async expand(expand: boolean) {
		this.expanded = expand;
	}
}
