import {
	Directive, ElementRef, HostListener, Input, OnChanges,
	Renderer2, SimpleChanges, TemplateRef, ViewContainerRef
} from "@angular/core";

@Directive({
	selector: "[appTooltip]"
})
export class HintDirective implements OnChanges {

	@Input() public tooltipTitle: string;
	@Input() public placement: string;
	@Input() public delay: string;
	@Input() public tooltipTemplateRef: TemplateRef<any>;
	@Input() public showTooltip: string;

	private tooltip: HTMLElement;
	private offset = 10;

	constructor(
		private readonly el: ElementRef,
		private readonly renderer: Renderer2,
		private readonly vcr: ViewContainerRef
	) { }

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.showTooltip) {
			if (this.showTooltip && this.needed() && !this.tooltip) {
				this.show();
			} else if (this.tooltip) {
				this.hide();
			}
		}
	}

	@HostListener("mouseenter")
	public onMouseEnter() {
		// if (this.needed() && !this.tooltip) {
		// 	this.show();
		// }
	}

	@HostListener("mouseleave")
	public onMouseLeave() {
		// if (!this.showTooltip && this.tooltip) {
		// 	this.hide();
		// }
	}

	private show() {
		this.create();
		this.setPosition();
		this.renderer.addClass(this.tooltip, "ng-tooltip-show");
	}

	private hide() {
		this.renderer.removeClass(this.tooltip, "ng-tooltip-show");
		window.setTimeout(() => {
			this.renderer.removeChild(document.body, this.tooltip);
			this.tooltip = null;
		}, +this.delay);
	}

	private create() {
		this.tooltip = this.renderer.createElement("div");
		const child = this.tooltipTemplateRef
			? this.vcr.createEmbeddedView(this.tooltipTemplateRef).rootNodes[0]
			: this.renderer.createText(this.tooltipTitle);
		this.renderer.appendChild(this.tooltip, child);
		this.renderer.appendChild(document.body, this.tooltip);
		this.renderer.addClass(this.tooltip, "ng-tooltip");
		this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
		this.renderer.setStyle(this.tooltip, "-webkit-transition", `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, "-moz-transition", `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, "-o-transition", `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, "transition", `opacity ${this.delay}ms`);
	}

	private setPosition() {
		const hostPos = this.el.nativeElement.getBoundingClientRect();
		const tooltipPos = this.tooltip.getBoundingClientRect();
		const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		let top: any;
		let left: any;

		if (this.placement === "top") {
			top = hostPos.top - tooltipPos.height - this.offset;
			left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
		}

		if (this.placement === "bottom") {
			top = hostPos.bottom + this.offset;
			left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
		}

		if (this.placement === "left") {
			top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
			left = hostPos.left - tooltipPos.width - this.offset;
		}

		if (this.placement === "right") {
			top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
			left = hostPos.right + this.offset;
		}

		this.renderer.setStyle(this.tooltip, "top", `${top + scrollPos}px`);
		this.renderer.setStyle(this.tooltip, "left", `${left}px`);
	}

	private needed() {
		return this.tooltipTemplateRef || this.tooltipTitle;
	}
}
