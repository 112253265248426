import { Injectable, NgZone } from "@angular/core";

import { AuthorizationService } from "@core/services/authorization.service";
import { BackendService } from "@core/services/backend.service";
import { NavigatorService } from "@core/services/navigator.service";
import { take } from "rxjs/operators";

@Injectable()
export class LogoutGuard {

	constructor(
		private authorizationService: AuthorizationService,
		private backendService: BackendService,
		private navigatorService: NavigatorService,
		private ngZone: NgZone,
	) { }

	public canActivate(): boolean {
		if (this.authorizationService.isAuthorized) {
			this.backendService.auth.logout.delete$().pipe(take(1)).subscribe();
			this.authorizationService.clean();
		}
		this.ngZone.run(() => this.navigatorService.goLoginPage());
		return false;
	}
}
