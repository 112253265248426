import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigurationService } from "../services/configuration.service";
import * as moment from "moment";
import { AccountEdit, Accounts, AccountStatement, AccountStatementFilter } from "@helper/types/accounts";
import { User } from "@helper/types/user";

export class AccountApi {
	public accounts = {
		get$: (statusAccount: number, actualize?: number): Observable<Accounts> => {
			const url = `${this.config.api.root}/accounts`;
			let params = new HttpParams();
			if (typeof statusAccount === "number") {
				params = params.set("statusAccount", statusAccount?.toString());
			}
			if (actualize) {
				params = params.set("actualize", actualize?.toString());
			}
			return this.http.get<Accounts>(url, { withCredentials: true, params });
		}
	};

	public accountStatement = {
		get$: (filter: AccountStatementFilter): Observable<AccountStatement> => {
			const url = `${this.config.api.root}/accounts/statement`;

			let params = new HttpParams();
			params = params.append("dateFrom", moment(filter.dateFrom).format("DD.MM.yyyy") + " " + "00:00:00");
			params = params.append("dateTo", moment(filter.dateTo).format("DD.MM.yyyy") + " " + "23:59:59");
			params = params.append("account", filter.account);
			params = params.append("accountSystem", filter.accountSystem);

			return this.http.get<AccountStatement>(url, { withCredentials: true, params });
		}
	};

	public editAccounts = {
		put$: (accounts: AccountEdit): Observable<void> => {
			const url = `${this.config.api.root}/accounts`;
			let headers = new HttpHeaders();
			headers = headers.set("Content-Type", "application/json");
			return this.http.put<void>(url, [accounts], { withCredentials: true, headers });
		}
	};

	public createUserList = {
		post$: (userData: User[]): Observable<any> => {
			const url = `${this.config.api.admin}/clients/list`;
			return this.http.post<void>(url, userData, { withCredentials: true });
		}
	};

	constructor(private readonly config: ConfigurationService, private readonly http: HttpClient) { }
}
