import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from "@angular/core";

interface Decade {
	date: Date;
	disabled: boolean;
	isOutside: boolean;
}
@Component({
	selector: "app-decade-picker",
	templateUrl: "./decade-picker.component.html",
	styleUrls: ["./../date-picker/date-picker.component.scss", "./decade-picker.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecadePickerComponent implements OnChanges {
	public now = new Date();
	@Input() public value?: Date;
	@Input() public typeRange = false;
	@Output() public valueChange = new EventEmitter<Date>();
	@Output() public changePicker = new EventEmitter<Date>();
	public isDisabled = true;
	public date: Date;
	public decades: Decade[] = [];

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
		this.date = this.now || new Date();
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.value && this.value) {
			this.setView(this.value);
		} else {
			this.date = this.now || new Date();
			this.setView(this.date);
		}

		if (simpleChanges.now && !this.now) {
			this.now = new Date();
		}
	}

	public switchDecade(date: Date, isForward: boolean): void {
		const today = new Date();
		const min = new Date(1900, 0, 1);
		const max = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
		date = new Date(date.valueOf());
		// date.setDate(1);
		if (min <= date && !isForward) {
			date.setFullYear(date.getFullYear() - 10);
		} else if (date < max && isForward) {
			date.setFullYear(date.getFullYear() + 10);
		} else {
			this.isDisabled = false;
		}
		this.date = date;
		this.setView(this.date);
		this.changeDetectorRef.detectChanges();
	}

	public isToday(date?: Date): boolean {
		return !!this.now && !!date && date.getFullYear() === this.now.getFullYear();
	}

	public isSelected(date?: Date): boolean {
		if (!this.value || !date) {
			return false;
		}
		return date.getFullYear() === this.value.getFullYear();
	}

	public disabled(date: Date): boolean {
		const value = new Date(date.getFullYear());
		const today = new Date();
		const min = new Date(today.getFullYear() - 20);
		const max = new Date(today.getFullYear() + 20);
		return !(min <= value && value <= max);
	}

	public setDecade(date?: Date): void {
		if (date) {
			this.value = new Date(date.valueOf());
			this.date = this.value;
			this.valueChange.emit(date);
		}
	}

	public setView(date?: Date): void {
		if (date) {
			let year = new Date(date.getFullYear() - 5, 0, 1);
			const years: Decade[] = [];
			do {
				years.push({
					date: year,
					disabled: false,
					isOutside: year.getFullYear() !== date.getFullYear(),
				});
				year = new Date(year.valueOf());
				year.setFullYear(year.getFullYear() + 1);
			} while (years.length < 12);
			this.decades = years;
			this.date = date;
			this.changeDetectorRef.detectChanges();
		}
	}
}
