import { Observable } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { ConfigurationService } from "../services/configuration.service";
import { AuthenticationDto, AuthenticationParams, ResetPassword, UserRemindLogin } from "@helper/types/authentication";

export class Auth {
	public restore = {
		post$: (body: ResetPassword): Observable<HttpResponse<any>> => {
			const url = `${this.config.api.root}/auth/reset`;
			return this.http.post<ResetPassword>(url, body, { observe: "response" });
		}
	};

	public login = {
		post$: (body: AuthenticationParams): Observable<HttpResponse<AuthenticationDto>> => {
			const url = `${this.config.api.root}/auth/login`;
			return this.http.post<AuthenticationDto>(url, body, { observe: "response" });
		}
	};

	public info = {
		get$: (): Observable<string> => {
			const url = `${this.config.api.root}/auth/info`;
			return this.http.get<string>(url, { responseType: "text" as "json" });
		}
	};

	public logout = {
		delete$: (): Observable<any> => {
			const url = `${this.config.api.root}/auth/logout`;
			return this.http.delete(url, { withCredentials: true });
		}
	};

	public remind = {
		post$: (body: UserRemindLogin): Observable<HttpResponse<any>> => {
			const url = `${this.config.api.root}/auth/remind`;
			return this.http.post<UserRemindLogin>(url, body, { observe: "response" });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
