import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigurationService } from "../services/configuration.service";
import * as moment from "moment";
import { VoteFilterFormValue } from "src/app/layout/vote/services/vote-filter-form-builder.service";
import { AddPoll, AddVoteAnswers, AlertDto, Poll, Member, QuestionAnswer } from "@helper/types/vote";

export class PollApi {
	public cancel = {
		delete$: (pollId: number): Observable<void> => {
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: pollId,
				withCredentials: true
			};
			return this.http.delete<void>(`${this.config.api.root}/poll/cancel/${pollId}`, options);
		}
	};

	public answers = {
		get$: (pollId: number, questionId: number): Observable<{ questions: QuestionAnswer[] }> => {
			const url = `${this.config.api.root}/poll/${pollId}/question_answer/${questionId}`;
			return this.http.get<{ questions: QuestionAnswer[] }>(url, { withCredentials: true });
		}
	};

	public answersList = {
		get$: (pollId: number): Observable<{ questions: QuestionAnswer[] }> => {
			const url = `${this.config.api.root}/poll/${pollId}/question_answer`;
			return this.http.get<{ questions: QuestionAnswer[] }>(url, { withCredentials: true });
		}
	};

	public alert = {
		post$: (alert: AlertDto): Observable<void> => {
			const url = `${this.config.api.root}/poll/notification/${alert.pollId}`;
			return this.http.post<void>(url, alert, { withCredentials: true });
		}
	};

	public members = {
		get$: (pollId: number, memberStatus?: number): Observable<{ members: Member[] }> => {
			const status = memberStatus ? `?memberStatus=${memberStatus}` : ``;
			const url = `${this.config.api.root}/poll/members/${pollId}${status}`;
			return this.http.get<{ members: Member[] }>(url, { withCredentials: true });
		}
	};

	public get$(filter: VoteFilterFormValue): Observable<{ polls: Poll[], confirmedCount: number | null, participantsCount: number | null }> {
		const url = `${this.config.api.root}/poll`;
		let params = new HttpParams();
		params = params.append("stopDateFrom", moment(filter.period[0]).format("DD.MM.yyyy") + " " + "00:00:00");
		params = params.append("stopDateTo", moment(filter.period[1]).format("DD.MM.yyyy") + " " + "23:59:59");
		params = params.append("status", filter.status.toString());
		if (filter.topic) {
			params = params.append("topic", filter.topic.toString());
		}

		return this.http.get<{ polls: Poll[], confirmedCount: number | null, participantsCount: number | null }>(url, { params, withCredentials: true });
	}

	public put$(poll: AddPoll): Observable<void> {
		const url = `${this.config.api.root}/poll/${poll.pollId}`;
		return this.http.put<void>(url, poll, { withCredentials: true });
	}

	public post$(poll: AddPoll): Observable<void> {
		const url = `${this.config.api.root}/poll`;
		return this.http.post<void>(url, poll, { withCredentials: true });
	}

	public delete$(pollId: number): Observable<void> {
		const url = `${this.config.api.root}/poll/${pollId}`;
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
			}),
			body: pollId,
			withCredentials: true
		};
		return this.http.delete<void>(url, options);
	}

	public vote = {
		post$: (vote: AddVoteAnswers): Observable<void> => {
			const url = `${this.config.api.root}/poll/vote`;
			return this.http.post<void>(url, vote, { withCredentials: true });
		}
	};

	constructor(private readonly config: ConfigurationService, private readonly http: HttpClient) { }
}
