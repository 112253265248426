import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

@Component({
	selector: "app-range-date-picker",
	templateUrl: "./range-date-picker.component.html",
	styleUrls: ["./range-date-picker.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeDatePickerComponent implements OnInit {
	@Input() public value?: [Date | null, Date | null];
	@Input() public picker?: "start" | "end";
	@Output() public valueChange = new EventEmitter<[Date | null, Date | null]>();
	public date = new Date();
	public now = new Date();
	public mode: "month" | "year" | "decade" = "month";

	public ngOnInit() {
		if (this.picker && this.picker === "end") {
			this.date = this.value && this.value[1] || this.now;
			return;
		}

		if (this.picker && this.picker === "start" && this.value[1]) {
			if (this.value[0].getMonth() !== this.value[1].getMonth()) {
				this.date = new Date(this.value[0]);
			} else {
				const endDate = new Date(this.value[1]);
				this.date = new Date(endDate.setMonth(endDate.getMonth() - 1));
			}
			return;
		}

		const date = this.now;
		this.date = this.value && this.value[0] || new Date(date.setMonth(date.getMonth() - 1));
	}

	public askYearView(date: Date): void {
		this.mode = "year";
		this.date = date;
	}

	public askMonthView(date: Date): void {
		this.mode = "month";
		this.date = date;
	}

	public askDecadeView(date: Date): void {
		this.mode = "decade";
		this.date = date;
	}

	public setValue(value: [Date, Date]): void {
		this.value = value;
		this.valueChange.emit(value);
	}
}
