import {
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectionStrategy
} from "@angular/core";

@Component({
	selector: "app-select-date",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "./select-date.component.html",
	styleUrls: ["./select-date.component.scss"]
})
export class SelectDateComponent {
	@Input() public value: Date;
	@Output() public appClose = new EventEmitter<Date | void>();

	public onValueChange(value: Date): void {
		this.value = value;
		this.appClose.emit(value);
	}
}
