import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { LayoutState } from "src/app/layout/layout.state";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";

@Injectable()
export class VoteAvailableGuard {
	@Select(LayoutState.pollAvailable) public pollAvailable$: Observable<boolean>;
	constructor(
		private router: Router
	) { }

	public canActivate(): Observable<boolean> {
		return this.pollAvailable$.pipe(
			filter(e => typeof e === "boolean"),
			switchMap(pollAvailable => {
				if (pollAvailable) {
					return of(true);
				} else {
					this.router.navigateByUrl("vote/paiement");
					return of(false);
				}
			})
		);
	}
}
