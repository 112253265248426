import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class DateInterceptor implements HttpInterceptor {
	public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!httpRequest.params && !httpRequest.body) {
			return next.handle(httpRequest);
		}

		const updateOption: any = {};

		if (httpRequest.params) {
			updateOption.params = this.replaceEachDateStringParams(httpRequest.params);
		}
		if (httpRequest.body) {
			updateOption.body = this.replaceEachDate(httpRequest.body);
		}

		return next.handle(httpRequest.clone(updateOption));
	}

	public toBackendDate(date: Date): string {
		return ("0" + date.getDate()).slice(-2) + "."
			+ ("0" + (date.getMonth() + 1)).slice(-2) + "."
			+ date.getFullYear();
	}

	// 29 === utc
	// 24 === iso
	private isDateString(str: string): boolean {
		return (str.length === 29 || str.length === 24) && !isNaN(Date.parse(str));
	}

	private replaceEachDateStringParams(params: HttpParams): HttpParams {
		let newParams = new HttpParams();
		for (const key of params.keys()) {
			const param = params.get(key);
			if (this.isDateString(param)) {
				newParams = newParams.set(
					key,
					this.toBackendDate(new Date(param))
				);
			} else {
				newParams = newParams.set(key, param);
			}
		}
		return newParams;
	}

	private replaceEachDate(object: any): any {
		for (const key in object) {
			if (object[key] instanceof Date) {
				object[key] = this.toBackendDate(object[key]);
			} else if (typeof object[key] === "object" && object[key] !== null && Object.keys(object[key]).length) {
				this.replaceEachDate(object[key]);
			}
		}

		return object;
	}
}
