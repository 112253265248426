import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthorizationService } from "@core/services/authorization.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
	constructor(private authorizationService: AuthorizationService) { }

	public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!httpRequest.withCredentials) {
			return next.handle(httpRequest);
		}

		return next.handle(
			httpRequest.clone({
				headers: httpRequest.headers
					.set("Authorization", `Bearer ${this.authorizationService.token}`)
					.set("X-Auth-TerminalId", this.authorizationService.terminalId)
			})
		);
	}
}
