import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from "@core/services/authorization.service";

@Injectable()
export class PermissionGuard {
	constructor(
		private readonly authorizationService: AuthorizationService,
		private readonly router: Router
	) { }

	public canActivate(): boolean {
		if (!this.authorizationService.isSuper) {
			this.router.navigate(["claims"]);
		}
		return this.authorizationService.isSuper;
	}

}
