import { FormStyle, getLocaleDayNames, getLocaleFirstDayOfWeek, TranslationWidth } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, Output, SimpleChanges } from "@angular/core";

interface Day {
	date: Date;
	disabled: boolean;
	isOutside: boolean;
}

@Component({
	selector: "app-month-picker",
	templateUrl: "./month-picker.component.html",
	styleUrls: ["./../date-picker/date-picker.component.scss", "./month-picker.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthPickerComponent implements OnChanges {
	public now = new Date();
	@Input() public value?: Date;
	@Input() public date: Date = new Date(); // It'll set now, if date not preset
	@Output() public valueChange = new EventEmitter<Date>();
	@Output() public changePicker = new EventEmitter<Date>();
	public isDisabled = true;
	public daysOfWeek?: string[];
	public days: Day[] = [];
	public rowRemoved?: boolean;

	constructor(
		@Inject(LOCALE_ID) private readonly localeId: string,
		private readonly changeDetectorRef: ChangeDetectorRef
	) { }

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.value && this.value) {
			this.setView(this.value);
		} else if (!this.date) {
			this.date = this.now || new Date();
			this.setView(this.date);
		}

		if (simpleChanges.now && !this.now) {
			this.now = new Date();
		}

		if (simpleChanges.date && this.date) {
			this.setView(this.date);
		}
	}

	public switchMonth(date: Date, isForward: boolean): void {
		const today = new Date();
		const min = new Date(today.getFullYear() - 20, today.getMonth(), today.getDate());
		const max = new Date(today.getFullYear() + 20, today.getMonth(), today.getDate());
		this.date = new Date(date.valueOf());
		if (min <= this.date && !isForward) {
			this.date.setMonth(date.getMonth() - 1);
		} else if (this.date < max && isForward) {
			this.date.setMonth(date.getMonth() + 1);

		} else {
			this.date = date;
			this.isDisabled = false;
		}
		this.setView(this.date);
		this.changeDetectorRef.detectChanges();
	}

	public isValidNextMonth(date: Date): boolean {
		const newDate = new Date(date.valueOf());
		newDate.setDate(1);
		newDate.setMonth(date.getMonth() + 1);
		if (newDate > this.now) {
			return true;
		} else {
			return false;
		}
	}

	public setDay(date: Date): void {
		this.value = new Date(date.valueOf());
		this.date = this.value;
		this.valueChange.emit(date);
	}

	public isToday(date: Date): boolean {
		return date.getFullYear() === this.now.getFullYear()
			&& date.getMonth() === this.now.getMonth()
			&& date.getDate() === this.now.getDate();
	}

	public disabled(date: Date): boolean {
		const value = new Date(
			date.getFullYear(),
			date.getFullYear() > new Date().getFullYear() ? date.getMonth() + 1 : date.getMonth(),
			date.getDate()
		);
		const today = new Date();
		const min = new Date(today.getFullYear() - 20, today.getMonth(), today.getDate());
		const max = new Date(today.getFullYear() + 20, today.getMonth(), today.getDate());
		return !(min <= value && value <= max);
	}

	public isSelected(date: Date): boolean {
		if (!this.value) {
			return false;
		}
		const value = new Date(this.value);
		return date.getFullYear() === value.getFullYear()
			&& date.getMonth() === value.getMonth()
			&& date.getDate() === value.getDate();
	}

	public capitalize(str?: string | null): string {
		return str ? (str.charAt(0).toUpperCase() + str.slice(1, str.length)) : "";
	}

	private setView(date: Date | string): void {
		if (date instanceof Date && !isNaN(date.getDate())) {
			date = new Date(date);
		} else {
			const dateArr = (date as string).split(".");
			date = new Date(`${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`);
			this.date = date;
		}
		const days: Day[] = [];
		let day = new Date(date.getFullYear(), date.getMonth(), 1);

		// locate first day of the week
		const firstDayOfWeek = getLocaleFirstDayOfWeek(this.localeId);
		const daysOfWeek = getLocaleDayNames(this.localeId, FormStyle.Standalone, TranslationWidth.Short)
			.map((e): string => this.capitalize(e)); // we need a copy of array because we will modify it
		for (let i = 0; i < firstDayOfWeek; i++) {
			daysOfWeek.push(daysOfWeek.shift() || "");
		}
		do {
			day.setDate(day.getDate() - 1);
		} while (day.getDay() !== firstDayOfWeek);

		do {
			days.push({
				date: day,
				disabled: false,
				isOutside: day.getMonth() !== date.getMonth(),
			});
			day = new Date(day.valueOf());
			day.setDate(day.getDate() + 1);
		} while (days.length < 42);

		this.daysOfWeek = daysOfWeek;
		this.days = days;
		this.rowRemoved = false;
		this.changeDetectorRef.detectChanges();
	}
}
