import { PlatformLocation } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class ConfigurationService {
	private readonly baseHref: string;

	public get api(): { root: string, admin: string } {
		return {
			root: `${this.baseHref}api`,
			admin: `${this.baseHref}api/admin`
		};
	}

	constructor(platformLocation: PlatformLocation) {
		this.baseHref = platformLocation.getBaseHrefFromDOM();
	}
}
