<section>
	<header>
		<label i18n>{{ headerText }}</label>
		<app-icon (click)="close$.next()">close</app-icon>
	</header>
	<main>
		<img [src]="dataUrl" alt="">
		<div *ngIf="debugData">
			<table>
				<tbody>
					<tr *ngIf="debugData.serviceCode">
						<th i18n>Код услуги:</th>
						<th>{{ debugData.serviceCode }}</th>
					</tr>
					<tr *ngIf="debugData.payerNumber">
						<th i18n>Номер счета:</th>
						<th>{{ debugData.payerNumber }}</th>
					</tr>
					<tr>
						<th i18n>Код валюты:</th>
						<th>933</th>
					</tr>
					<tr *ngIf="debugData.sum !== null && debugData.sum !== undefined">
						<th i18n>Сумма к оплате:</th>
						<th>{{ debugData.sum }}</th>
					</tr>
					<tr *ngIf="debugData.organizationShortName">
						<th i18n>Наименование организации:</th>
						<th>{{ debugData.organizationShortName }}</th>
					</tr>
				</tbody>
			</table>
			<button *ngIf="payLink" (click)="copyLink()" type="button" class="copy" i18n>Копировать ссылку</button>
		</div>
	</main>
</section>