import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "@core/services/configuration.service";
import { Profile } from "@helper/types/profile";
import { AlgorithmListRequest, Service } from "@helper/types/service";
import { UserTypes } from "@helper/types/user";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Account } from "@helper/types/accounts";
import { BaseInterface } from "@helper/types/refund";

type ServicesDto = Service[];

export interface ProfileDto {
	accountStatement: {
		accounts: Account[]
	};
	pollAvailable: boolean;
	supplierId: number;
	accNum: string;
	address: string;
	basis: string;
	contact: ContactDto[];
	confirmStatus: boolean;
	errorCode: number;
	errorText: string;
	fio: string;
	fullName: string;
	keyRegistration: string;
	keyRequest: number;
	mfo: string;
	post: string;
	shortName: string;
	testMode: "0";
	unp: string;
	usersCount: number;
	version: number;
	usePaymentCabinet: number | null;
	useBillingScheme: boolean;
}
export type GetFilterRequest = GetFilterResponse & BaseInterface;
export interface GetFilterResponse {
	filter: Filter;
}
export interface Filter {
	item: {
		name: string;
		type: string;
		value: any;
	}[];
}


export interface ContactDto {
	contact: string;
	phone: string;
	position: string;
}

export class Corporation {
	public services = {
		get$: (): Observable<Service[]> => {
			const url = `${this.config.api.root}/corporation/services`;
			return this.http.get<ServicesDto>(url, { withCredentials: true });
		}
	};

	public information = {
		get$: (): Observable<Profile> => {
			const url = `${this.config.api.root}/corporation/information`;
			return this.http.get<ProfileDto>(url, { withCredentials: true }).pipe(
				map(dto => ({
					pollAvailable: dto.pollAvailable,
					accountStatement: dto.accountStatement,
					organizationId: dto.supplierId,
					organizationFullName: dto.fullName,
					organizationShortName: dto.shortName,
					keyRegistration: dto.keyRegistration,
					confirmStatus: dto.confirmStatus,
					legalAddress: dto.address,
					mfi: dto.mfo,
					payerNumber: dto.accNum,
					pan: dto.unp,
					post: dto.post,
					responsibilityPerson: dto.fio,
					responsibilityPersonPosition: dto.basis,
					usePaymentCabinet: dto.usePaymentCabinet,
					useBillingScheme: dto.useBillingScheme,
				}))
			);
		}
	};

	public algorithms = {
		get$: (id: number): Observable<AlgorithmListRequest> => {
			const url = `${this.config.api.root}/corporation/services/${id}/algorithms`;
			return this.http.get<AlgorithmListRequest>(url, { withCredentials: true });
		}
	};

	public postFilter$(filter: GetFilterRequest): Observable<GetFilterResponse> {
		const url = `${this.config.api.root}/corporation/filters`;
		return this.http.post<GetFilterResponse>(url, filter, { withCredentials: true });
	}

	public addAlgorithm = {
		post$: (serviceCode: number, algorithms: AlgorithmListRequest): Observable<void> => {
			const url = `${this.config.api.root}/corporation/services/${serviceCode}/algorithms`;
			return this.http.post<void>(url, algorithms, { withCredentials: true });
		}
	};

	public editAlgorithm = {
		put$: (serviceCode: number, algorithms: AlgorithmListRequest): Observable<void> => {
			const url = `${this.config.api.root}/corporation/services/${serviceCode}/algorithms/${algorithms.id}`;
			return this.http.put<void>(url, algorithms, { withCredentials: true });
		}
	};

	public deleteAlgorithm = {
		delete$: (serviceCode: number, algorithmId: number): Observable<void> => {
			const url = `${this.config.api.root}/corporation/services/${serviceCode}/algorithms/${algorithmId}`;
			return this.http.delete<void>(url, { withCredentials: true });
		}
	};

	public clientTypes = {
		get$: (): Observable<UserTypes> => {
			const url = `${this.config.api.root}/corporation/dictionaries/CLIENT_TYPE`;
			return this.http.get<UserTypes>(url, { withCredentials: true });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
