import { Injectable, NgZone } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { filter } from "rxjs/operators";
import { AuthorizationService } from "./authorization.service";
import { UserRoleConst } from "@core/constants/user-roles.const";

@Injectable()
export class NavigatorService {
	private previousUrl: string;
	private currentUrl: string;
	constructor(
		private router: Router,
		private location: Location,
		private ngZone: NgZone,
		private authorizationService: AuthorizationService
	) {
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((e: NavigationEnd) => {
			this.previousUrl = this.currentUrl || "";
			this.currentUrl = e.url;
		});
	}

	public goMainPage(): void {
		switch (this.authorizationService.userRole) {
			case UserRoleConst.ADMIN_SUPERADMIN.id:
			case UserRoleConst.ADMIN_ADMIN.id:
			case UserRoleConst.CABINET_SUPERUSER.id:
			case UserRoleConst.CABINET_USER.id:
				this.ngZone.run(() => this.router.navigateByUrl("claims"));
				break;
			case UserRoleConst.CABINET_POLL_MANUAL_ENTRY_USER.id:
				this.ngZone.run(() => this.router.navigateByUrl("vote"));
				break;
		}
	}

	public goLoginPage(): void {
		this.ngZone.run(() => this.router.navigateByUrl("login"));
	}

	public goBack(): void {
		this.ngZone.run(() => this.location.back());
	}

	public go(url: string): void {
		this.ngZone.run(() => this.router.navigateByUrl(url));
	}

	public getPreviousUrl(): string {
		return this.previousUrl;
	}
}
