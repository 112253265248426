<div (appClickOutside)="onClickOutside()" [class.error]="isShowError" [class.disabled]="disabled" class="wrapper">
	<app-icon *ngIf="isSingle && value && value[1]" (click)="isHighlightTree(); $event.stopPropagation()" [class.default]="highlightTree === false">
		{{highlightTree === undefined ? "indeterminate_check_box" : highlightTree? "check_box" : "null"}}
	</app-icon>

	<label *ngIf="label" [style.padding-left]="!isSingle ? '8px': '0'"
		[class.selected]="multiChecked && (allNameValue?.length || isCheckAll && allNameValue?.length === data.length)">
		{{isSingle ? value && value[1] : multiChecked && (allNameValue?.length || isCheckAll && allNameValue?.length === data.length) ? displayAllName : label }}
	</label>

	<app-icon [style.margin-left]="isSingle ? 'auto': ''">keyboard_arrow_down</app-icon>
	<div *ngIf="!collapse && displayingData && !disabled" class="list">
		<app-text-box *ngIf="isSearch || data?.length > 10" (input)="searchHandler($event)" (click)="$event.stopPropagation();" placeholder="Поиск">
		</app-text-box>
		<ul>
			<li *ngIf="!isSingle && displayingData.length" (click)="checkAll(checked?.length === 0); $event.stopPropagation()" [attr.disabled]="isEveryItemsDisabled() ? true : null">
				<app-icon [class.checked]="checked?.length ">
					{{ !checked?.length ? null : displayingData?.length === checked?.length ? "check_box" :
					"indeterminate_check_box" }}
				</app-icon>
				<span i18n>Выбрать все</span>
			</li>
			<li *ngFor="let item of displayingData; trackBy: trackBy;" [class.without-border]="data?.length === 1"
				[class.disabled]="multiplyCheckedDisable?.length && isSetDisabled(item[0]) === true? true : null && multiChecked"
				[class.can-part-check]="multiplyCheckedDisable?.length && isSetDisabled(item[0]) === undefined && multiChecked" (click)="check(item); $event.stopPropagation()">
				<app-icon *ngIf="!isSingle" [class.checked]="isChecked(item)">
					{{ isChecked(item) ? "check_box" : null }}
				</app-icon>
				<span>{{item[1]}}</span>
				<app-icon *ngIf="isEditable" [attr.enabled]="true" class="delete-icon" [style.margin-left]="'auto'" (click)="delete(item); $event.stopPropagation()">
					close
				</app-icon>
			</li>

		</ul>
		<button *ngIf="isEditable" [attr.disabled]="isBlockAdding? true : null" icon="add_circle" type="button" (click)="add()" i18n>
			Добавить
		</button>
	</div>
</div>
