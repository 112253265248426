import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NotificationService } from "@core/services/notification.service";
import { AuthorizationService } from "@core/services/authorization.service";
import { NavigatorService } from "@core/services/navigator.service";
import { ConstantsService } from "@core/constants/constants.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private readonly notificationService: NotificationService,
		private constantsService: ConstantsService,
		private authorizationService: AuthorizationService,
		private navigatorService: NavigatorService
	) { }

	public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(httpRequest).pipe(
			catchError(error => {
				const status = error.status;
				if (status === 401 || status === 403) {
					if (this.authorizationService.isAuthorized) {
						this.notificationService.showNotification("error", this.constantsService.getConstant("tokenExpired"));
						this.authorizationService.clean();
						this.navigatorService.goLoginPage();
					}
				} else if (status !== 412) {
					if (status === 500) {
						this.notificationService.showNotification("error", error.error.message || this.constantsService.getConstant("500"));
					} else if (status === 503) {
						this.notificationService.showNotification("error", this.constantsService.getConstant("503") || error.error.message);
					} else if (status === 504) {
						this.notificationService.showNotification("error", this.constantsService.getConstant("504") || error.error.message);
					} else {
						this.notificationService.showNotification("error", error.error.message);
					}
				}
				return throwError(error);
			})
		);
	}
}
