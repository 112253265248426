<header>
	<app-icon [class.disabled]="isDisabled" (click)="switchDecade(date, false);$event.stopPropagation();" class="left-icon">
		keyboard_arrow_left</app-icon>
	<div class="button" (click)="changePicker.emit(date);$event.stopPropagation();">{{date?.getFullYear() - 5}}
		- {{date?.getFullYear() + 6}}</div>
	<app-icon [class.disabled]="isDisabled" (click)="switchDecade(date, true);$event.stopPropagation()" class="right-icon">
		keyboard_arrow_right</app-icon>
</header>
<app-grid-drawer [columns]="4" [rows]="3" [items]="decades">
	<ng-template #itemTemplate let-item>
		<div class="grid-item" [class.today]="isToday(item.date)" [class.selected]="isSelected(item.date)" [class.disabled]="disabled(item.date)" (click)="setDecade(item.date);$event.stopPropagation()">
			{{item.date.getFullYear()}}</div>
	</ng-template>
</app-grid-drawer>
