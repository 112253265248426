<main>
	<app-range-date-picker [value]="value" picker="start" (valueChange)="onValueChange($event, 1)" (keyup.enter)="onEnter($event)">
	</app-range-date-picker>
	<app-range-date-picker [value]="value" picker="end" (valueChange)="onValueChange($event, 2)" (keyup.enter)="onEnter($event)">
	</app-range-date-picker>
</main>

<footer>
	<span>
		<ng-container *ngIf="value[0] && value[1]">
			<span i18n>Период с {{value[0] | date:'dd.MM.yyy'}} по {{value[1] | date:'dd.MM.yyy'}}</span>
		</ng-container>
	</span>
	<button class="cancel" (click)="appClose.emit()" i18n>Отменить</button>
	<button class="select" (click)="setRange(value[0], value[1])" i18n>Выбрать</button>
</footer>