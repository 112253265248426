<div appClickOutside (appClickOutside)="collapse = true">
	<input [ngClass]="{disabled_color: disabledColor}" (click)="collapse = !collapse" readonly="true" [value]="selected[1]" type="text" [attr.disabled]="disabled">
	<app-icon [ngClass]="{disabled_color: disabledColor}" *ngIf="disabled !== ''" (click)="collapse = !collapse">{{disabledColor ? "": "keyboard_arrow_down"}}</app-icon>
	<ul *ngIf="!collapse && data?.length" [ngClass]="{scroll_enabled: scrollEnabled}">
		<li *ngFor="let element of data" [title]="element[1]" (click)="select(element)" [attr.class]="isLang ? 'lang' : ''">
			<app-icon *ngIf="colorMap && element[0] !== 0 && element[0] !== null" class="color" [ngStyle]="{ 'color': getColorVar(colorMap.get(element[0])) }">stop</app-icon>
			<a *ngIf="isLang; else noLang" href="/{{element[0]}}{{this.router.url}}"><span>{{element[1]}}</span></a>
			<ng-template #noLang>
				<span>{{element[1]}}</span>
			</ng-template>
		</li>
	</ul>
</div>
