import { AlgorithmListRequest } from "./../helper/types/service";
import { Injectable } from "@angular/core";
import { BackendService } from "@core/services/backend.service";
import { Profile } from "@helper/types/profile";
import { Service } from "@helper/types/service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import * as LayoutActions from "./layout.actions";
import { AccountStatement } from "@helper/types/accounts";
import { Refund } from "@helper/types/refund";

export interface LayoutStateModel {
	profile?: Profile;
	services: Service[];
	refundWithoutRegister?: Refund[];
	algorithmList: AlgorithmListRequest[];
	accountStatement?: AccountStatement;
	pending: boolean;
}

@Injectable()
@State<LayoutStateModel>({
	name: "layout",
	defaults: {
		pending: false,
		services: [],
		algorithmList: [],
		refundWithoutRegister: []
	}
})
export class LayoutState {
	@Selector()
	public static pending(state: LayoutStateModel): boolean { return state.pending; }

	@Selector()
	public static services(state: LayoutStateModel): [Service, string][] { return state.services.map(e => [e, e.serviceName]); }

	@Selector()
	public static refundWithoutRegister(state: LayoutStateModel): Refund[] { return state.refundWithoutRegister; }

	@Selector()
	public static service(state: LayoutStateModel): (serviceCode: number) => Service {
		return (serviceCode: number): Service => state.services.find(service => service.serviceCode === serviceCode);
	}

	@Selector()
	public static rawServices(state: LayoutStateModel): Service[] { return state.services; }

	@Selector()
	public static accountStatement(state: LayoutStateModel): AccountStatement { return state.accountStatement; }

	@Selector()
	public static algorithmsList(state: LayoutStateModel): [number, string][] { return state.algorithmList.map(e => [e.id, e.name]); }

	@Selector()
	public static algorithmList(state: LayoutStateModel): AlgorithmListRequest[] { return state.algorithmList; }

	@Selector()
	public static profile(state: LayoutStateModel): Profile { return state.profile; }

	@Selector()
	public static pollAvailable(state: LayoutStateModel): boolean | null { return state.profile.pollAvailable; }

	@Selector()
	public static usePaymentCabinet(state: LayoutStateModel): number | null { return state.profile?.usePaymentCabinet; }

	@Action(LayoutActions.RefreshServices)
	public refreshServices(ctx: StateContext<LayoutStateModel>): Observable<Service[]> {
		return this.backendService.corporation.services.get$().pipe(
			tap((services: Service[]) => {
				ctx.patchState({ services });
			})
		);
	}

	@Action(LayoutActions.Reset)
	public reset(ctx: StateContext<LayoutStateModel>): void {
		ctx.patchState({
			services: [],
			profile: undefined
		});
	}

	@Action(LayoutActions.GetProfile)
	public get(ctx: StateContext<LayoutStateModel>): Observable<any> {
		return this.backendService.corporation.information.get$().pipe(
			tap(profile => ctx.patchState({ profile }))
		);
	}

	@Action(LayoutActions.GetRefundsWithoutRegister)
	public getRefundsWithoutRegister(ctx: StateContext<LayoutStateModel>, action: LayoutActions.GetRefundsWithoutRegister): Observable<Refund[]> {
		return this.backendService.refunds.get$(action.serviceId).pipe(
			tap(refunds => {
				ctx.patchState({
					refundWithoutRegister: refunds.filter(reg => reg.registerId === null),
				});
			})
		);
	}


	@Action(LayoutActions.ResetAccountStatement)
	public resetAccountStatement(ctx: StateContext<LayoutStateModel>): void {
		ctx.patchState({
			accountStatement: undefined
		});
	}

	@Action(LayoutActions.ResetAlgorithmList)
	public resetAlgorithmList(ctx: StateContext<LayoutStateModel>): void {
		ctx.patchState({
			algorithmList: undefined
		});
	}

	@Action(LayoutActions.GetAlgorithmList)
	public getAlgorithmList(ctx: StateContext<LayoutStateModel>, action: LayoutActions.GetAlgorithmList): Observable<any> {
		return this.backendService.corporation.algorithms.get$(action.id).pipe(
			tap(algorithmList => ctx.patchState({ algorithmList }))
		);
	}

	@Action(LayoutActions.AddAlgorithmList)
	public addAlgorithmList(ctx: StateContext<LayoutStateModel>, action: LayoutActions.AddAlgorithmList): Observable<any> {
		return this.backendService.corporation.addAlgorithm.post$(action.serviceCode, action.algorithm);
	}

	@Action(LayoutActions.EditAlgorithmList)
	public editAlgorithmList(ctx: StateContext<LayoutStateModel>, action: LayoutActions.EditAlgorithmList): Observable<any> {
		return this.backendService.corporation.editAlgorithm.put$(action.serviceCode, action.algorithm);
	}

	@Action(LayoutActions.DeleteAlgorithmList)
	public delete(ctx: StateContext<LayoutStateModel>, action: LayoutActions.DeleteAlgorithmList): Observable<any> {
		return this.backendService.corporation.deleteAlgorithm.delete$(action.serviceCode, action.algorithmId).pipe(
			tap(() => {
				const list = ctx.getState().algorithmList;
				const listAlgorithm = list.filter(field => field.id !== action.algorithmId);
				ctx.patchState({ algorithmList: listAlgorithm });
			})
		);
	}

	@Action(LayoutActions.GetAccountStatement)
	public getAccountStatement(ctx: StateContext<LayoutStateModel>, action: LayoutActions.GetAccountStatement): Observable<any> {
		ctx.patchState({ pending: true });
		return this.backendService.account.accountStatement.get$(action.filter).pipe(
			tap(accountStatement => {
				ctx.patchState({
					accountStatement,
					pending: false
				});
			}),
			catchError(e => {
				ctx.patchState({ pending: false });
				return throwError(e);
			})
		);
	}

	@Action(LayoutActions.DeleteRefundWithoutRegister)
	public deleteWithoutRegister(ctx: StateContext<LayoutStateModel>, action: LayoutActions.DeleteRefundWithoutRegister): Observable<any> {
		return this.backendService.refunds.delete$(action.refundPaymentId).pipe(
			tap(() => {
				const refunds = ctx.getState().refundWithoutRegister;
				const newRefunds = refunds.filter(field => !action.refundPaymentId.includes(field.refundPaymentId));
				ctx.patchState({ refundWithoutRegister: newRefunds} );
			})
		);
	}

	constructor(
		private backendService: BackendService
	) { }
}
