import * as moment from "moment";
import { NewClaimForm } from "../layout/claims/services/new-claim-form-builder.service";
import { Claim, NotificationMode } from "./types/claim";
import { ClaimDto } from "./types/claim-dto";

export class ClaimsUtil {
	public static dtoToClaim(dto: ClaimDto): Claim {
		const invoiceDate = moment(dto.invoiceDate, "DD.MM.YYYY HH:mm:ss").format();
		const dueDate = moment(dto.dueDate, "DD.MM.YYYY HH:mm:ss").format();
		return {
			id: dto.claimId,
			payerNumber: dto.accNum,
			invoiceDate: new Date(invoiceDate),
			dueDate: new Date(dueDate),
			fullName: {
				surname: dto.fullName?.surName || dto.fullName?.value?.split(" ")[0] || "",
				name: dto.fullName?.firstName || dto.fullName?.value?.split(" ")[1] || "",
				patronymic: dto.fullName?.patronymic || dto.fullName?.value?.split(" ")[2] || "", /* cspell: disable-line */
			},
			description: dto.productCharacter,
			meteringDevices: {
				count: dto.device?.count,
				lastPaidValues: dto.device?.lastPaidValues?.map(deviceDto => ({
					idx: deviceDto.idx,
					algorithmId: deviceDto.algorithmId,
					counterWidth: deviceDto.counterWidth,
					deviceName: deviceDto.nameDevice,
					unitName: deviceDto.itemUnit,
					tariff: deviceDto.tariff,
					value: deviceDto.value,
					prevReg: deviceDto.prevReg
				}))
			},
			sum: {
				value: dto.sum.value,
				quantity: dto.sum.quantity,
				standingSum: dto.sum.standingSum
			},
			paymentSum: dto.paymentSum,
			email: dto.email,
			notificationPolitic: dto.phone && dto.email ? NotificationMode.Full : dto.phone ? NotificationMode.SmsOnly : dto.email ? NotificationMode.EmailOnly : NotificationMode.Mute,
			phone: dto.phone,
			status: dto.status,
			serviceCode: dto.serviceCode,
			address: dto.address,
			qrCode: dto.qrCode
		};
	}

	public static claimToDto(claim: NewClaimForm): Partial<ClaimDto> {
		const time = claim.paymentAttributes.dueDateTime ? claim.paymentAttributes.dueDateTime.split(":") : "23:59".split(":");
		claim.paymentAttributes.dueDate.setHours(+time[0]);
		claim.paymentAttributes.dueDate.setMinutes(+time[1]);
		return {
			accNum: claim.paymentAttributes.payerNumber,
			// invoiceDate: ; // "dd.MM.yyyy"
			dueDate: moment(claim.paymentAttributes.dueDate).format("DD.MM.yyyy HH:mm:ss"), // "dd.MM.yyyy"
			fullName: Object.keys(claim.payer).length ? {
				surName: claim.payer.surname,
				firstName: claim.payer.name, /* cspell: disable-line */
				patronymic: claim.payer.patronymic /* cspell: disable-line */
			} : undefined,
			productCharacter: claim.paymentAttributes.description,
			device: { // <Device Count="1"><LastReg Idx="0" Algorithm_Id="0" NameDevice="dName7" Tariff="5.0">55.0</LastReg></Device>
				count: claim.meteringDevices.length,
				lastPaidValues: claim.meteringDevices.map((device, index) => {
					const objectLastPaidValues = {
						idx: index + 1, // might be 1, 2 ...
						nameDevice: device.deviceName,
						tariff: +device.tariff,
						itemUnit: device.unitName,
						counterWidth: +device.counterWidth,
						algorithmId: device.algorithmId,
						value: +device.value
					};
					if (!objectLastPaidValues.tariff) {
						delete objectLastPaidValues.tariff;
					}
					if (objectLastPaidValues.itemUnit === "" || objectLastPaidValues.itemUnit === null) {
						delete objectLastPaidValues.itemUnit;
					}
					return objectLastPaidValues;

				}) as any[]
			},
			sum: {
				quantity: claim.paymentAttributes.quantity,
				value: claim.paymentAttributes.sum,
				standingSum: claim.paymentAttributes.standingSum ? 1 : 0
			},
			notificationType: claim.notification.notificationPolitic,
			email: claim.notification.email,
			phone: claim.notification.phone,
			serviceCode: claim.paymentAttributes.service,
			address: claim.payer.address
		};
	}
}
