import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-notification",
	templateUrl: "./notification.component.html",
	styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
	@Input() public type: "error" | "warning" | "success" | "init" | "init-wide" = "success";
	@Input() public message?: string | string[];
	public close$$ = new Subject<void>();
	public env = environment;
	public messageArr: string[] = [];

	public ngOnInit(): void {
		if (Array.isArray(this.message)) {
			this.message = this.message.join();
		}
		if (this.message.includes("\n")) {
			this.messageArr = this.message.split("\n");
		}
	}
}
