import { Directive, EventEmitter, Output, HostListener } from "@angular/core";

@Directive({
	selector: "[appUpload]"
})
export class UploadDirective {
	@Output() public appUpload = new EventEmitter<FileList>();

	@HostListener("click")
	public onClick() {
		const input = document.createElement("input", {});
		input.setAttribute("type", "file");
		input.onchange = (event: Event) => {
			this.appUpload.emit((event.target as HTMLInputElement).files);
		};
		input.click();
	}
}
