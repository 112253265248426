import { Component, Input, forwardRef, Type, HostBinding, HostListener, Output, EventEmitter, OnChanges } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<CheckBoxComponent> => CheckBoxComponent)
	}],
	selector: "app-check-box",
	template: `
	<app-icon>{{ isIndeterminate ? "indeterminate_check_box" : value ? "check_box" : "check_box_outline_blank" }}</app-icon>
	`,
	styleUrls: ["./check-box.component.scss"]
})
export class CheckBoxComponent extends BoxValueAccessor<boolean> implements OnChanges {
	@HostBinding("class.disabled") public get isDisabled() {
		return this.disabled;
	}
	@Input() public isIndeterminate?: boolean;
	@Input() public checked?: boolean;
	@Input() public disabled: boolean;
	@Output() public appChange = new EventEmitter<boolean>();

	@HostListener("click")
	public click(): void {
		if (!this.disabled) {
			this.value = !this.value;
			if (this.onChange) { this.onChange(this.value); }
			if (this.onTouched) { this.onTouched(this.value); }
			this.appChange.emit(this.value);
		}
	}

	public ngOnChanges() {
		this.value = this.checked;
	}
}
