import { Injectable, Inject, ComponentFactoryResolver, Type, ApplicationRef, Injector, Optional, EmbeddedViewRef, ComponentRef } from "@angular/core";
import { OverlayComponent } from "@shared/overlay/overlay.component";

type OriginShowComponent = OverlayComponent["showComponent"];
type OriginShowConfirmation = OverlayComponent["showConfirmation"];
type SkipFirstParameters<T> = T extends (arg1: any, ...args: infer U) => any ? U : any;

@Injectable({
	providedIn: "root"
})
export class DrawComponentService {
	public overlayComponent?: OverlayComponent;

	constructor(
		componentFactoryResolver: ComponentFactoryResolver,
		@Inject("OverlayComponentType") @Optional() private overlayComponentType: Type<OverlayComponent>,
		applicationRef: ApplicationRef,
		injector: Injector
	) {
		if (this.overlayComponentType) {
			const componentFactory = componentFactoryResolver.resolveComponentFactory(this.overlayComponentType);
			const newComponentInstance = componentFactory.create(injector);
			this.overlayComponent = newComponentInstance.instance;
			applicationRef.attachView(newComponentInstance.hostView);
			const element = (newComponentInstance.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			document.body.appendChild(element);
		}
	}

	public showComponent<T>(component: Type<T>, ...args: SkipFirstParameters<Parameters<OriginShowComponent>>): ComponentRef<T> {
		return this.overlayComponent.showComponent(component, ...args) as ComponentRef<T>;
	}

	public showConfirmation(...args: Parameters<OriginShowConfirmation>): ReturnType<OriginShowConfirmation> {
		return this.overlayComponent.showConfirmation(...args);
	}

	public clear(): void {
		return this.overlayComponent.destroy();
	}
}
