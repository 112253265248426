import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";
import { HttpClient } from "@angular/common/http";
import { Auth } from "@core/api/auth";
import { Claims } from "@core/api/claims";
import { Corporation } from "@core/api/corporation";
import { Payments } from "@core/api/payments";
import { Messages } from "@core/api/messages";
import { Registration } from "@core/api/registration";
import { Admin } from "@core/api/admin";
import { Observable } from "rxjs";
import { AccountApi } from "@core/api/account";
import { PollApi } from "@core/api/poll";
import { Refunds } from "@core/api/refunds";

@Injectable()
export class BackendService {
	public readonly auth: Auth;
	public readonly claims: Claims;
	public readonly payments: Payments;
	public readonly refunds: Refunds;
	public readonly corporation: Corporation;
	public readonly messages: Messages;
	public readonly registration: Registration;
	public readonly admin: Admin;
	public readonly account: AccountApi;
	public readonly poll: PollApi;

	constructor(configurationService: ConfigurationService, private httpClient: HttpClient) {
		this.auth = new Auth(configurationService, httpClient);
		this.claims = new Claims(configurationService, httpClient);
		this.payments = new Payments(configurationService, httpClient);
		this.corporation = new Corporation(configurationService, httpClient);
		this.messages = new Messages(configurationService, httpClient);
		this.registration = new Registration(configurationService, httpClient);
		this.admin = new Admin(configurationService, httpClient);
		this.account = new AccountApi(configurationService, httpClient);
		this.poll = new PollApi(configurationService, httpClient);
		this.refunds = new Refunds(configurationService, httpClient);
	}

	public getLocalInfo(): Observable<string> {
		return this.httpClient.get<string>("assets/buildinfo.txt", { responseType: "text" as "json" });
	}
}
