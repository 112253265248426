import { LogoComponent } from "./logo/logo.component";
import { NgModule } from "@angular/core";
import { IconComponent } from "./icon/icon.component";
import { SelectBoxComponent } from "./controls/select-box/select-box.component";
import { TextBoxComponent } from "./controls/text-box/text-box.component";
import { RangeDateBoxComponent } from "./controls/range-date-box/range-date-box.component";
import { CommonModule } from "@angular/common";
import { GridTextSearchBoxComponent } from "./controls/grid-text-search-box/grid-text-search-box.component";
import { DateBoxComponent } from "./controls/date-box/date-box.component";
import { OverlayComponent } from "./overlay/overlay.component";
import { NotificationComponent } from "./notification/notification.component";
import { ClickOutsideDirective } from "./directives/click-outside/click-outside.directive";
import { YearPickerComponent } from "./year-picker/year-picker.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { SelectDateRangeComponent } from "./select-date-range/select-date-range.component";
import { RangeDatePickerComponent } from "./range-date-picker/range-date-picker.component";
import { MonthRangePickerComponent } from "./month-range-picker/month-range-picker.component";
import { MonthPickerComponent } from "./month-picker/month-picker.component";
import { GridDrawerComponent } from "./grid-drawer/grid-drawer.component";
import { DecadePickerComponent } from "./decade-picker/decade-picker.component";
import { SelectDateComponent } from "./select-date/select-date.component";
import { UploadDirective } from "./directives/upload/upload.directive";
import { CheckBoxComponent } from "./controls/check-box/check-box.component";
import { NumberBoxComponent } from "./controls/number-box/number-box.component";
import { PendingComponent } from "./pending/pending.component";
import { QRCodeViewComponent } from "@shared/QR-code-view/QR-code-view.component";
import { TextareaComponent } from "./controls/textarea/textarea.component";
import { ConfirmPopupComponent } from "./confirm-popup/confirm-popup.component";
import { ConfirmDeleteClaimsComponent } from "./confirm-popup/confirm-delete-claims/confirm-delete-claims.component";
import { SelectBoxSelfFetchComponent } from "@shared/controls/select-box-self-fetch/select-box-self-fetch.component";
import { ListComponent } from "@shared/list/list.component";
import { MaskedTextBoxComponent } from "@shared/controls/masked-text-box/masked-text-box.component";
import { HintDirective } from "@shared/hint/hint.directive";
import { HintComponent } from "@shared/hint/hint.component";
import { PasswordBoxComponent } from "./controls/password-box/password-box.component";
import { RadioBtnListComponent } from "./controls/radio-btn-list/radio-btn-list.component";
import { MultiSelectBoxComponent } from "./multi-select-box/multi-select-box.component";

@NgModule({
	imports: [
		CommonModule
	],
	exports: [
		RadioBtnListComponent,
		ConfirmDeleteClaimsComponent,
		CheckBoxComponent,
		ClickOutsideDirective,
		DateBoxComponent,
		GridTextSearchBoxComponent,
		IconComponent,
		NotificationComponent,
		NumberBoxComponent,
		PasswordBoxComponent,
		OverlayComponent,
		PendingComponent,
		RangeDateBoxComponent,
		SelectBoxComponent,
		TextBoxComponent,
		TextareaComponent,
		UploadDirective,
		QRCodeViewComponent,
		ConfirmPopupComponent,
		SelectBoxSelfFetchComponent,
		MultiSelectBoxComponent,
		ListComponent,
		MaskedTextBoxComponent,
		HintDirective,
		HintComponent,
		LogoComponent,
		YearPickerComponent
	],
	declarations: [
		RadioBtnListComponent,
		ConfirmDeleteClaimsComponent,
		CheckBoxComponent,
		ClickOutsideDirective,
		DateBoxComponent,
		DatePickerComponent,
		DecadePickerComponent,
		GridDrawerComponent,
		GridTextSearchBoxComponent,
		IconComponent,
		MonthPickerComponent,
		MonthRangePickerComponent,
		NotificationComponent,
		NumberBoxComponent,
		PasswordBoxComponent,
		OverlayComponent,
		PendingComponent,
		RangeDateBoxComponent,
		RangeDatePickerComponent,
		SelectBoxComponent,
		SelectDateComponent,
		SelectDateRangeComponent,
		TextBoxComponent,
		TextareaComponent,
		UploadDirective,
		YearPickerComponent,
		QRCodeViewComponent,
		ConfirmPopupComponent,
		SelectBoxSelfFetchComponent,
		MultiSelectBoxComponent,
		ListComponent,
		MaskedTextBoxComponent,
		HintDirective,
		HintComponent,
		LogoComponent,
	],
	providers: [
		{ provide: "OverlayComponentType", useValue: OverlayComponent }
	]
})
export class SharedModule { }
