import { Payment, PaymentRefundFormValue, PaymentRefundParams } from "./types/payment";
import { PaymentDto } from "./types/payment-dto";

export class PaymentsUtil {
	public static dtoToPayment(dto: PaymentDto): Payment {
		const paymentDocumentDate = dto.memDate && (dto.memDate as any).split(".");
		const pDateTime = dto.paymentDate && (dto.paymentDate as any).split(/\.|:|\s+/); // "21.02.2021 22:45:56".split(/\.|:|\s+/) -> ["21", "02", "2021", "22", "45", "56"]
		return {
			serviceProvision: dto.serviceProvision,
			accountNumber: dto.accNum,
			address: dto.address,
			bankCode: dto.bankCode,
			claimId: dto.claimId,
			device: {
				count: dto.device?.count,
				lastPaidValues: dto.device?.lastPaidValues,
			},
			transferSum: dto.transferSum,
			refundAvailableSum: dto.refundAvailableSum,
			refundSum: dto.refundSum,
			commissionSum: dto.commissionSum,
			fullName: dto.fio,
			paymentDocumentDate: paymentDocumentDate && new Date(+paymentDocumentDate[2], +paymentDocumentDate[1] - 1, +paymentDocumentDate[0]),
			paymentDocumentNumber: dto.memNumber,
			paymentDate: pDateTime && new Date(pDateTime[2], pDateTime[1] - 1, pDateTime[0], pDateTime[3], pDateTime[4], pDateTime[5]),
			paymentId: dto.paymentId,
			paymentStatus: dto.paymentStatus,
			paymentSum: dto.paymentSum,
			description: dto.productCharacter,
			providerOperId: dto.providerOperId,
			authorizationType: dto.authorizationType,
		};
	}

	public static paymentToRefundPaymentParams(payment: Payment, additionalInfo: PaymentRefundFormValue): PaymentRefundParams {
		return {
			fullName: {
				firstName: additionalInfo.payer.name,
				surName: additionalInfo.payer.surname,
				patronymic: additionalInfo.payer.patronymic,
			},
			paymentPurpose: additionalInfo.paymentPurpose,
			refundPayment: {
				accNum: additionalInfo.accNum,
				bankMFO: additionalInfo.bankMFO,
				paymentOrderDate: additionalInfo?.paymentOrderDate,
				paymentOrderNumber: additionalInfo?.paymentOrderNumber,
				refundAmount: +additionalInfo?.refundAmount,
				providerOperId: payment.providerOperId,
				value: payment.paymentId,
			},
		};
	}
}
