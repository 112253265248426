import { Component, Inject, InjectionToken, ModuleWithProviders, NgModule, Type } from "@angular/core";
import { ConstantsService } from "./constants.service";

export const CONSTANTS_FEATURE_COMPONENTS = new InjectionToken<Type<Component>[]>("DICTIONARY_FEATURE_COMPONENTS");
export const CONSTANTS_ROOT_COMPONENTS = new InjectionToken<Type<Component>[]>("DICTIONARY_ROOT_COMPONENTS");

@NgModule({})
export class ConstantsRootModule {
	constructor(
	@Inject(CONSTANTS_ROOT_COMPONENTS) roots: Type<Component>[],
		private readonly dictionaryService: ConstantsService
	) {
		this.addConstants(roots);
	}

	public addConstants(constantsComponents: Type<Component>[]): void {
		this.dictionaryService.addConstants(constantsComponents);
	}
}

@NgModule({})
export class ConstantsFeatureModule {
	constructor(
	@Inject(CONSTANTS_FEATURE_COMPONENTS) feature: Type<Component>[],
		root: ConstantsRootModule
	) {
		root.addConstants(feature);
	}
}

@NgModule({})
export class ConstantsModule {
	public static forRoot(constantsComponents: Type<Component>[]): ModuleWithProviders<ConstantsModule> {
		return {
			ngModule: ConstantsRootModule,
			providers: [
				ConstantsService,
				{ provide: CONSTANTS_ROOT_COMPONENTS, useValue: constantsComponents },
			]
		};
	}

	public static forFeature(features: Type<Component>[]): ModuleWithProviders<ConstantsModule> {
		return {
			ngModule: ConstantsFeatureModule,
			providers: [
				{ provide: CONSTANTS_FEATURE_COMPONENTS, useValue: features },
			]
		};
	}
}
