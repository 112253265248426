import { Component, EventEmitter, forwardRef, Type, Input, HostBinding, ChangeDetectionStrategy, OnChanges, Output, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextBoxComponent } from "../text-box/text-box.component";
import { Router } from "@angular/router";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef(<T>(): Type<SelectBoxComponent<T>> => SelectBoxComponent)
	}],
	selector: "app-select-box",
	templateUrl: "./select-box.component.html",
	styleUrls: ["./select-box.component.scss", "../controls.scss"]
})
export class SelectBoxComponent<T> extends TextBoxComponent<T> implements OnChanges {
	@Input() public isLang = false;
	@Input() public data?: [T | null, string][] | null = [];
	@Input() public colorMap?: Map<number, string>;
	@Input() public scrollEnabled = false;
	@Output() public onSelected: EventEmitter<[T, string]> = new EventEmitter();
	public collapse = true;
	public value: T | null = null;
	public selected: [T | null, string] = [null, ""];
	@HostBinding("attr.disabled") public disabled;

	constructor(
		public readonly router: Router,
		changeDetectorRef: ChangeDetectorRef
	) {
		super(changeDetectorRef);
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if ((!simpleChanges.data?.previousValue || !simpleChanges.data?.previousValue.length) && simpleChanges.data?.currentValue && simpleChanges.data?.currentValue.length) {
			this.selected = this.data.find(element => element[0] === this.value) ?? this.selected;
		}
	}

	public select(item: [T, string]): void {
		this.writeValue(item[0]);
		if (this.onChange) { this.onChange(item[0]); }
		if (this.onTouched) { this.onTouched(item[0]); }
		this.selected = item.slice(0) as [T, string]; // new ref
		this.collapse = true;
	}

	public writeValue(value: T): void {
		this.value = value;
		if (this.data) {
			this.selected = this.data.find(element => element[0] === value) ?? this.selected;
		}
		this.onSelected.emit(this.selected);
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled ? "" : null;
	}

	public getColorVar(styleName: string, transparent = 1): string {
		return styleName ? `rgba(var(--${styleName}), ${transparent})` : "inherit";
	}
}
