<section>
	<header *ngIf="headerText">
		<h2 i18n>{{ headerText }}</h2>
		<app-icon (click)="appConfirm$.emit(false)">close</app-icon>
	</header>

	<main *ngIf="mainText" i18n [style.color]="">{{ mainText }}</main>
	<p *ngIf="message" i18n>{{ message }}</p>
	<app-confirm-delete-claims *ngIf="deletedClaims" [selected]="selected" [deletedClaims]="deletedClaims">
	</app-confirm-delete-claims>

	<nav>
		<button *ngIf="!withoutDisagree && disagreeButtonText" (click)="appConfirm$.emit(false)" type="reset">{{disagreeButtonText}}</button>
		<button *ngIf="!withoutDisagree && !disagreeButtonText" (click)="appConfirm$.emit(false)" type="reset" i18n>Отменить</button>

		<button *ngIf="agreeButtonText" (click)="appConfirm$.emit(true)">{{agreeButtonText}}</button>
		<button *ngIf="!agreeButtonText" (click)="appConfirm$.emit(true)" i18n>Подтвердить</button>
	</nav>
</section>
