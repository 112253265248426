<header>
	<app-icon [class.disabled]="isDisabled" (click)="switchYear(date, false);$event.stopPropagation()" class="left-icon">keyboard_arrow_left
	</app-icon>
	<div class="button" (click)="changePicker.emit(date);$event.stopPropagation()">
		{{date | date:'yyyy'}}</div>
	<app-icon [class.disabled]="isDisabled" (click)="switchYear(date, true);$event.stopPropagation()" class="right-icon">keyboard_arrow_right
	</app-icon>
</header>
<app-grid-drawer [columns]="4" [rows]="3" [items]="years">
	<ng-template #itemTemplate let-item>
		<div class="grid-item" [class.today]="isToday(item.date)" [class.selected]="isSelected(item.date)" [class.disabled]="disabled(item.date)" (click)="setYear(item.date);$event.stopPropagation();">
			{{item.date |date:'LLL'}}</div>
	</ng-template>
</app-grid-drawer>
