import { NgModule } from "@angular/core";
import { BackendService } from "./services/backend.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthorizationGuard } from "./guards/authorization.guard";
import { AuthorizationService } from "./services/authorization.service";
import { AuthenticationInterceptor } from "./interceptors/authentication.interceptor";
import { ConfigurationService } from "./services/configuration.service";
import { NavigatorService } from "./services/navigator.service";
import { LogoutGuard } from "./guards/logout.guard";
import { DateInterceptor } from "./interceptors/date.interceptor";
import { NotificationService } from "./services/notification.service";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { DrawComponentService } from "./services/draw-component.service";
import { ReactiveFormsModule } from "@angular/forms";
import { ProjectGuard } from "@core/guards/project.guard";
import { UserGuard } from "@core/guards/user.guard";
import { PermissionGuard } from "@core/guards/permission.guard";
import { HeadersInterceptor } from "./interceptors/headers.interceptor";
import { LanguageService } from "./services/language.service";
import { VoteAvailableGuard } from "./guards/vote-available.guard";
import { VoteUnavailableGuard } from "./guards/vote-unavailable.guard";
import { ConstantsComponent } from "./constants/constants.component";
import { ConstantsModule } from "./constants/constants.module";

@NgModule({
	declarations: [],
	imports: [
		HttpClientModule,
		ReactiveFormsModule,
		ConstantsModule.forRoot([ConstantsComponent]),
	],
	providers: [
		VoteAvailableGuard,
		VoteUnavailableGuard,
		AuthorizationGuard,
		PermissionGuard,
		AuthorizationService,
		BackendService,
		ConfigurationService,
		LogoutGuard,
		NavigatorService,
		NotificationService,
		DrawComponentService,
		ProjectGuard,
		UserGuard,
		LanguageService,
		{ provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true }
		// { provide: ErrorHandler, useClass: AppErrorHandler }
	]
})
export class CoreModule { }
