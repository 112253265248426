import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule as AppRouterModule } from "./router/router.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "@shared/shared.module";
import { NgxsModule } from "@ngxs/store";


@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		AppRouterModule,
		BrowserModule,
		CoreModule,
		SharedModule,
		NgxsModule.forRoot([]),
		NgxsReduxDevtoolsPluginModule.forRoot()
	],
	providers: [],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
