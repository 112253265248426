import { Component, Input, OnChanges, SimpleChanges, forwardRef, Type, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { TemplateUtil } from "@helper/template.util";

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<RadioBtnListComponent> => RadioBtnListComponent)
	}],
	selector: "app-radio-btn-list",
	styleUrls: ["./radio-btn-list.component.scss"],
	templateUrl: "./radio-btn-list.component.html"
})

export class RadioBtnListComponent extends BoxValueAccessor<any> implements OnInit, OnDestroy, OnChanges {
	@Input() public data: [string, string][] | HTMLElement = [];
	@Input() public isChange = true;
	@Input() public label?: string;
	@Input() public isRequired = false;
	@Input() public formControlName?: string;
	@Input() public formControl?: AbstractControl;
	@Input()
	public set isDisabled(value: boolean) {
		this.disabled = value ? "" : null;
	}
	public disabled: "" | null = null;
	public error = false;
	public control?: AbstractControl | null;
	public isShowError = false;
	public valueList = {
		true: true,
		false: false,
		null: null
	};
	public list: [any, string][] = [];
	private unsubscribe$$ = new Subject<void>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private controlContainer: ControlContainer
	) { super(); }

	public ngOnInit(): void {
		if (this.formControl) { this.control = this.formControl; }

		if (this.controlContainer && this.formControlName && this.controlContainer.control) { this.control = this.controlContainer.control.get(this.formControlName); }

		this.control?.statusChanges?.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.error) {
				this.isShowError = this.control && this.control.touched && this.control.invalid || false;
			} else { this.isShowError = this.error; }
			this.changeDetectorRef.detectChanges();
		});
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.data) {
			if (this.data instanceof Element || this.data instanceof HTMLDocument) {
				this.list = (TemplateUtil.getArray(this.data as HTMLElement)).map(item => {
					if (this.valueList.hasOwnProperty(item[0])) {
						return [this.valueList[item[0]], item[1]];
					}
					return item;
				});
			} else if (Array.isArray(this.data)) {
				this.list = this.data.slice(0);
			} else { throw Error("Invalid data format"); }
			this.value = this.list[0][0];
		}
	}

	public checkItem(item: string): void {
		if (!this.disabled) {
			this.writeValue(item);
		}
	}

	public writeValue(item: string): void {
		this.value = item;
		if (this.onChange) { this.onChange(item); }
		if (this.onTouched) { this.onTouched(); }
		this.changeDetectorRef.markForCheck();
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
