<ng-container [ngSwitch]="mode">
	<app-month-picker *ngSwitchCase="'month'" [value]="value" [date]="date" (valueChange)="setValue($event);"
		(changePicker)="askYearView($event)">
	</app-month-picker>

	<app-year-picker *ngSwitchCase="'year'" [value]="date" (valueChange)="askMonthView($event)"
		(changePicker)="askDecadeView($event)">
	</app-year-picker>

	<app-decade-picker *ngSwitchCase="'decade'" [value]="date" (valueChange)="askYearView($event)"
		(changePicker)="askMonthView($event)">
	</app-decade-picker>
</ng-container>
