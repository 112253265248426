import {
	Component,
	forwardRef,
	HostBinding,
	Type
} from "@angular/core";
import { TextBoxComponent } from "../text-box/text-box.component";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<GridTextSearchBoxComponent> => GridTextSearchBoxComponent)
	}],
	selector: "app-grid-text-search-box",
	templateUrl: "./grid-text-search-box.component.html",
	styleUrls: ["./grid-text-search-box.component.scss"]
})
export class GridTextSearchBoxComponent extends TextBoxComponent {
	public focus = false;

	@HostBinding("class.focus") public get isFocus(): boolean {
		return this.focus;
	}
}
