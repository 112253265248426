import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "app-confirm-popup",
	templateUrl: "./confirm-popup.component.html",
	styleUrls: ["./confirm-popup.component.scss"]
})
export class ConfirmPopupComponent {
	@Input() public headerText?: string;
	@Input() public mainText?: string;
	@Input() public message?: string;
	@Input() public selected?: number;
	@Input() public deletedClaims?: number;
	@Input() public agreeButtonText?: string;
	@Input() public disagreeButtonText?: string;
	@Input() public withoutDisagree = false;
	@Output() public appConfirm$ = new EventEmitter<boolean>();
}
