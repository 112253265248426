<div (appClickOutside)="selectedRange()">
	<input (click)="collapse = !collapse" type="text" placeholder="с" [value]="value && value[0] | date: 'dd.MM.yyyy'"
		readonly>
	<input (click)="collapse = !collapse" type="text" placeholder="по" [value]="value && value[1] | date: 'dd.MM.yyyy'"
		readonly>
	<app-icon (click)="collapse = !collapse">today</app-icon>

	<app-select-date-range *ngIf="!collapse" [value]="value" (appClose)="selectedRange($event)">
	</app-select-date-range>
</div>
