<dl>
  <dt>restoreError</dt>
  <dd i18n>Неверные данные. Повторите ввод или обратитесь к администратору</dd>
  <dt>successChangePassword</dt>
  <dd i18n>Пароль успешно изменен</dd>
  <dt>unhandledError</dt>
  <dd i18n>Произошла внутрення ошибка</dd>
  <dt>500</dt>
  <dd i18n>Произошла внутренняя техническая ошибка</dd>
  <dt>503</dt>
  <dd i18n>"Мой Дом" временно не доступен</dd>
  <dt>504</dt>
  <dd i18n>Сервис "Мой дом" не отвечает</dd>
  <dt>201</dt>
  <dd i18n>Новое требование создано</dd>
  <dt>201.2</dt>
  <dd i18n>Новые требования созданы</dd>
  <dt>invalidForm</dt>
  <dd i18n>Необходимо заполнить обязательные поля</dd>
  <dt>deletedSuccessfully</dt>
  <dd i18n>Успешно удалено</dd>
  <dt>checkWholeMaxLength</dt>
  <dd i18n>Последнее показание счетчика превышает его разрядность.</dd>
  <dt>counterWidthValue</dt>
  <dd i18n>Значение поля "Разрядность" должно быть в диапазоне от 1 до 16.</dd>
  <dt>nameRequired</dt>
  <dd i18n>Нужно ввести имя</dd>
  <dt>surnameRequired</dt>
  <dd i18n>Нужно ввести фамилию</dd>
  <dt>invalidDate</dt>
  <dd i18n>Данные внесены некорректно</dd>
  <dt>claimsQR</dt>
  <dd i18n>QR-код для оплаты</dd>
  <dt>serviceQR</dt>
  <dd i18n>QR-код услуги</dd>
  <dt>notification</dt>
  <dd i18n>Уведомление</dd>
  <dt>confirmDeleteAlgorithm</dt>
  <dd i18n>Вы действительно хотите удалить настройку алгоритма?</dd>
  <dt>confirmCancelVote</dt>
  <dd i18n>Вы действительно хотите отменить голосование?</dd>
  <dt>confirmDeleteVote</dt>
  <dd i18n>Вы действительно хотите удалить голосование?</dd>
  <dt>confirmDeleteClaim</dt>
  <dd i18n>Вы действительно хотите удалить требование?</dd>
  <dt>confirmDeleteClaims</dt>
  <dd i18n>Вы действительно хотите удалить требования?</dd>
  <dt>confirmDeleteMessage</dt>
  <dd i18n>Вы действительно хотите удалить уведомление?</dd>
  <dt>confirmDeleteMessages</dt>
  <dd i18n>Вы действительно хотите удалить уведомления?</dd>
  <dt>confirmReadMessages</dt>
  <dd i18n>Вы действительно хотите пометить уведомления как прочитанные?</dd>
  <dt>delete</dt>
  <dd i18n>Удалить</dd>
  <dt>cancel</dt>
  <dd i18n>Отменить</dd>
  <dt>paymentCancelRefundHeader</dt>
  <dd i18n>Отмена возврата платежа</dd>
  <dt>paymentCancelRefundText</dt>
  <dd i18n>Вы уверены, что хотите отменить возврат денежных средств по выбранному платежу?</dd>
  <dt>paymentCancelRefundAgreeButton</dt>
  <dd i18n>Аннулировать возврат</dd>
  <dt>removingAllRefundsHeader</dt>
  <dd i18n>Удаление всех записей</dd>
  <dt>removingAllRefundsText</dt>
  <dd i18n>Все записи реестра возвратов будут удалены</dd>
  <dt>paymentCancelRefundDisagreeButton</dt>
  <dd i18n>Отменить</dd>
  <dt>close</dt>
  <dd i18n>Закрыть</dd>
  <dt>read</dt>
  <dd i18n>Прочитать</dd>
  <dt>deviceCountError</dt>
  <dd i18n>Проверьте код услуги во внешней системе. Для услуги с указанным кодом запрещен ввод показаний индивидуальных приборов учета</dd>
  <dt>algorithmError</dt>
  <dd i18n>Проверьте алгоритм.Не соответствует ни одному коду алгоритма из списка "Настройки алгоритма"</dd>

  <!-- ERRORS START -->
  <dt>invalidOldPassword</dt>
  <dd i18n>Неправильно введен старый пароль. Повторите ввод</dd>
  <dt>required</dt>
  <dd i18n>Не заполнены обязательные поля</dd>
  <dt>incorrectEmail</dt>
  <dd i18n>Некорректный E-mail</dd>
  <dt>oldAndNewPasswordCannotMatch</dt>
  <dd i18n>Старый и новый пароль не могут совпадать. Повторите ввод</dd>
  <dt>other</dt>
  <dd i18n>Данные внесены некорректно</dd>
  <dt>mustMatch</dt>
  <dd i18n>Введенные пароли не совпадают</dd>
  <dt>userCreated</dt>
  <dd i18n>Пользователь создан</dd>
  <dt>usersCreated1</dt>
  <dd i18n>Зарегистрированы</dd>
  <dt>usersCreated2</dt>
  <dd i18n>из</dd>
  <dt>usersCreated3</dt>
  <dd i18n>пользователей.</dd>
  <dt>userAlreadyExists</dt>
  <dd i18n>Пользователь с таким логином уже существует</dd>
  <dt>userChanged</dt>
  <dd i18n>Пользователь изменен</dd>
  <dt>userDelete</dt>
  <dd i18n>Пользователи удалены</dd>
  <dt>usersDelete1</dt>
  <dd i18n>Всего для удаления было выбрано</dd>
  <dt>usersDelete2</dt>
  <dd i18n>учетных записей пользователей, из них успешно удалено</dd>
  <dt>usersDelete3</dt>
  <dd i18n>Оставшиеся не могут быть удалены, т.к. для них найдена зависимая информация. Для запрета доступа к веб-приложению заблокируйте их.</dd>
  <!-- ERRORS END -->

  <!-- create-user-input-card START -->
  <dt>tokenExpired</dt>
  <dd i18n>Срок жизни токена истёк</dd>
  <dt>confirmationCodeSent</dt>
  <dd i18n>На указанный e-mail будет отправлен код подтверждения</dd>
  <dt>name</dt>
  <dd i18n>ФИО</dd>
  <dt>password</dt>
  <dd i18n>Пароль</dd>
  <dt>repeatPassword</dt>
  <dd i18n>Повторите пароль</dd>
  <dt>enterHeadOfficeCode</dt>
  <dd i18n>Ввести код головной организации</dd>
  <dt>headOrganizationCode</dt>
  <dd i18n>Код головной организации</dd>
  <dt>confirmationCode</dt>
  <dd i18n>Код-подтверждения</dd>
  <dt>confirmationCodeEmail</dt>
  <dd i18n>Код подтверждения отправлен на указанный e-mail</dd>
  <!-- create-user-input-card END -->

  <!-- registration.service START -->
  <dt>generalInformation</dt>
  <dd i18n>Общие данные</dd>
  <dt>headOrganization</dt>
  <dd i18n>Руководитель организации</dd>
  <dt>chiefAccountant</dt>
  <dd i18n>Главный бухгалтер</dd>
  <!-- registration.service END -->

  <!-- new-claim.component START -->
  <dt>claimAmount</dt>
  <dd i18n>Сумма требования больше максимально допустимой</dd>
  <dt>currentValueCapacity</dt>
  <dd i18n>Текущее значение счетчика превышает его разрядность.</dd>
  <dt>errorSumm1</dt>
  <dd i18n>Значение в поле "Сумма" не должно превышать заданного для данной услуги (код услуги</dd>
  <dt>errorSumm2</dt>
  <dd i18n>) значения "Максимальной суммы требования" в</dd>
  <!-- new-claim.component END -->

  <!-- payments-unload-statement-file.service START -->
  <dt>totalPaymentDocument</dt>
  <dd i18n>Итого по платежному документу :</dd>
  <dt>paymentDocument</dt>
  <dd i18n>Платежный документ</dd>
  <dt>dateTimeIssue</dt>
  <dd i18n>Дата, время выдачи:</dd>
  <dt>paymentAcceptanceSheet</dt>
  <dd i18n>Ведомость принятой оплаты</dd>
  <dt>byService</dt>
  <dd i18n>по услуге</dd>
  <dt>periodFrom</dt>
  <dd i18n>за период с</dd>
  <dt>on</dt>
  <dd i18n>по</dd>
  <!-- payments-unload-statement-file.service END -->

  <!-- create-user.component START -->
  <dt>userPU1</dt>
  <dd i18n>Пользователь ПУ-1</dd>
  <dt>userPU2</dt>
  <dd i18n>Пользователь ПУ-2</dd>
  <dt>userPU3</dt>
  <dd i18n>Пользователь ПУ-3</dd>
  <dt>notChosen</dt>
  <dd i18n>Не выбрано</dd>
  <dt>all</dt>
  <dd i18n>Все</dd>
  <!-- create-user.component END -->

  <!-- users-grid-more.component START -->
  <dt>youWantDelete</dt>
  <dd i18n>Вы действительно хотите удалить пользователя?</dd>
  <dt>removal</dt>
  <dd i18n>Удаление</dd>
  <dt>reset</dt>
  <dd i18n>Сбросить</dd>
  <dt>sureYouWantReset</dt>
  <dd i18n>Вы действительно хотите сбросить пароль пользователя?</dd>
  <dt>passwordReset</dt>
  <dd i18n>Сброс пароля</dd>
  <dt>userDeleted</dt>
  <dd i18n>Пользователь удален</dd>
  <dt>unableDeleteAccount</dt>
  <dd i18n>
    Невозможно удалить выбранную учетную запись пользователя, т.к. есть зависимая информация. Для запрета доступа к веб-приложению заблокируйте
    учетную запись
  </dd>
  <dt>resetPassword</dt>
  <dd i18n>Пароль сброшен</dd>
  <!-- users-grid-more.component END -->

  <!-- users.component START -->
  <dt>youWantDeleteUsers</dt>
  <dd i18n>Вы действительно хотите удалить выбранных пользователей?</dd>
  <dt>block</dt>
  <dd i18n>Заблокировать</dd>
  <dt>youWantBlock</dt>
  <dd i18n>Вы действительно хотите заблокировать выбранных пользователей?</dd>
  <dt>blocking</dt>
  <dd i18n>Заблокирование</dd>
  <dt>unlock</dt>
  <dd i18n>Разблокировать</dd>
  <dt>youWantUnblock</dt>
  <dd i18n>Вы действительно хотите разблокировать выбранных пользователей?</dd>
  <dt>unlocking</dt>
  <dd i18n>Разблокирование</dd>
  <dt>accountsCannotLocked</dt>
  <dd i18n>
    Невозможно заблокировать выбранные учетные записи пользователей, т.к. есть зависимая информация. Для запрета доступа к веб-приложению заблокируйте
    учетную запись
  </dd>
  <dt>accountsCannotUnlocked</dt>
  <dd i18n>
    Невозможно разблокировать выбранные учетные записи пользователей, т.к. есть зависимая информация. Для запрета доступа к веб-приложению
    заблокируйте учетную запись
  </dd>
  <dt>usersBlocked</dt>
  <dd i18n>Пользователи заблокированы</dd>
  <dt>usersUnlocked</dt>
  <dd i18n>Пользователи разблокированы</dd>
  <dt>doNotNotify</dt>
  <dd i18n>Не уведомлять</dd>
  <dt>notifybyEmail</dt>
  <dd i18n>Уведомить по E-mail</dd>
  <!-- users.component END -->

  <!-- vote START -->
  <dt>201.3</dt>
  <dd i18n>Новое голосование создано</dd>
  <dt>201.4</dt>
  <dd i18n>Голосование отредактировано</dd>
  <dt>voteSuccessAlert</dt>
  <dd i18n>Оповещение отправлено</dd>
  <dt>successDeleteVote</dt>
  <dd i18n>Голосование удалено</dd>
  <dt>open</dt>
  <dd i18n>Открытое</dd>
  <dt>closed</dt>
  <dd i18n>Закрытое</dd>
  <!-- vote END -->

  <!-- vote status START -->
  <dt>statusVote2</dt>
  <dd i18n>Завершено</dd>
  <dt>statusVote-2</dt>
  <dd i18n>Отменено</dd>
  <dt>statusVote-1</dt>
  <dd i18n>Черновик</dd>
  <dt>statusVote1</dt>
  <dd i18n>Идет голосование</dd>
  <dt>statusVote-3</dt>
  <dd i18n>Публикация</dd>
  <dt>statusVote-4</dt>
  <dd i18n>Подсчет голосов</dd>
  <!-- vote status END -->

  <dt>periodVoteError</dt>
  <dd i18n>Дата и время начала действия голосования должны быть меньше даты и времени конца действия голосования</dd>

  <dt>cancelRefundRecords</dt>
  <dd i18n>Отмена реестра возвратов</dd>
  <dt>reallyWantCancel</dt>
  <dd i18n>Вы действительно хотите отменить</dd>
  <dt>cancelRequestPrepared</dt>
  <dd>Подготовлена заявка на отмену</dd>

  <dt>notSelectedLable</dt>
  <dd>Не указано</dd>
  <dt>allLable</dt>
  <dd i18n>Все</dd>

  <!--claims-grid-header => claimHeadersTemplate START -->
  <dt>id</dt>
  <dd i18n>Код требования</dd>
  <dt>payerNumber</dt>
  <dd i18n>Номер счета</dd>
  <dt>invoiceDate</dt>
  <dd i18n>Выставлено</dd>
  <dt>dueDate</dt>
  <dd i18n>Срок оплаты</dd>
  <dt>fullName</dt>
  <dd i18n>ФИО</dd>
  <dt>description</dt>
  <dd i18n>Описание</dd>
  <dt>sum</dt>
  <dd i18n>Сумма</dd>
  <dt>paymentSum</dt>
  <dd i18n>Сумма оплаты</dd>
  <dt>selectedClaims</dt>
  <dd i18n>Всего выбрано записей</dd>
  <dt>deletedClaims</dt>
  <dd i18n>Будет удалено записей</dd>
  <!--claims-grid-header=> claimHeadersTemplate END -->

  <!--vote-grid-more => downloadVoteReport START -->
  <dt>protocol</dt>
  <dd i18n>ПРОТОКОЛ</dd>
  <dt>date</dt>
  <dd i18n>Дата</dd>
  <dt>endDate</dt>
  <dd i18n>Дата окончания голосования</dd>
  <dt>result</dt>
  <dd i18n>Результаты голосования</dd>
  <dt>idx</dt>
  <dd i18n>№</dd>
  <dt>value</dt>
  <dd i18n>Текст вопроса</dd>
  <dt>abstainedCount</dt>
  <dd i18n>Воздержались</dd>
  <dt>abstained</dt>
  <dd i18n>Воздержался</dd>
  <dt>count</dt>
  <dd i18n>Проголосовали/Зарегистрированы/Участники</dd>
  <dt>protocolVote</dt>
  <dd i18n>Протокол голосования</dd>
  <dt>listMembers</dt>
  <dd i18n>Список членов организации, не принявших участие в голосовании</dd>
  <dt>clientAccount</dt>
  <dd i18n>Лицевой счет</dd>
  <dt>address</dt>
  <dd i18n>Адрес</dd>
  <dt>entrance</dt>
  <dd i18n>Подъезд</dd>
  <dt>flat</dt>
  <dd i18n>Квартира</dd>
  <dt>phone</dt>
  <dd i18n>Телефон</dd>
  <dt>email</dt>
  <dd i18n>e-mail</dd>
  <dt>voted</dt>
  <dd i18n>Подтвержден</dd>
  <dt>yes</dt>
  <dd i18n>Да</dd>
  <dt>no</dt>
  <dd i18n>Нет</dd>
  <dt>yesUp</dt>
  <dd i18n>ДA</dd>
  <dt>againstUp</dt>
  <dd i18n>ПРОТИВ</dd>
  <dt>abstainUp</dt>
  <dd i18n>ВОЗД.</dd>
  <dt>question</dt>
  <dd i18n>№ вопроса</dd>
  <dt>answer</dt>
  <dd i18n>Ответ</dd>
  <dt>answerVoteError</dt>
  <dd i18n>Для сохранения необходимо ответить на все вопросы</dd>
  <dt>commonInfoText1</dt>
  <dd i18n>В голосовании приняли участие</dd>
  <dt>commonInfoText2</dt>
  <dd i18n>(в том числе</dd>
  <dt>commonInfoText3</dt>
  <dd i18n>онлайн) собственников из</dd>
  <dt>commonInfoText4</dt>
  <dd i18n>, отобранных для голосования, что составляет</dd>
  <dt>commonInfoText5</dt>
  <dd i18n>% собственников, обладающих</dd>
  <dt>commonInfoText6</dt>
  <dd i18n>% голосов (долей собственности) от общего количества голосов, отобранных для голосования.</dd>
  <dt>numberPA</dt>
  <dd i18n>кол-во ЛС,</dd>
  <dt>numberOwners</dt>
  <dd i18n>кол-во собственников,</dd>
  <dt>allOwnersPercent</dt>
  <dd i18n>% от всех собственников в голосовании,</dd>
  <dt>whoVoted</dt>
  <dd i18n>% от проголосовавших,</dd>
  <dt>areaShareText1</dt>
  <dd i18n>% голосов (доля собственности) от общего количества голосов, отобранных для голосования,</dd>
  <dt>areaShareVotedText1</dt>
  <dd i18n>% голосов (доля собственности) от проголосовавших</dd>
  <dt>areaShareText2</dt>
  <dd i18n>%, доля собственности в голосовании</dd>
  <dt>areaShareVotedText2</dt>
  <dd i18n>%, доля собственности от проголосовавших</dd>

  <dt>manualReport</dt>
  <dd i18n>Отчет о ручном внесении бюллетеней</dd>
  <dt>creationDate</dt>
  <dd i18n>Дата формирования</dd>
  <dt>apartmentNumber</dt>
  <dd i18n>Номер квартиры</dd>
  <dt>typeProperty</dt>
  <dd i18n>Тип Л.С.</dd>
  <dt>totalArea</dt>
  <dd i18n>Общая площадь</dd>
  <dt>partInVoteLower</dt>
  <dd i18n>доля в голосовании (%)</dd>
  <dt>partInVote</dt>
  <dd i18n>Доля в голосовании</dd>
  <dt>partInOrganization</dt>
  <dd i18n>Доля в организации</dd>
  <dt>questionNumber</dt>
  <dd i18n>Вопрос</dd>
  <dt>clientCodeInSystem</dt>
  <dd i18n>Код клиента в системе</dd>
  <dt>manualInput</dt>
  <dd i18n>Ручной ввод</dd>
  <dt>totals</dt>
  <dd i18n>Итоги</dd>
  <dt>additionalRowText</dt>
  <dd i18n>Кол-во собственников</dd>
  <!--vote-grid-more => downloadVoteReport END -->

  <!--payments-grid => titleAuthorizationType START -->
  <dt>MS</dt>
  <dd i18n>Магнитная полоска карточки</dd>
  <dt>CHIP</dt>
  <dd i18n>Микропроцессор карточки</dd>
  <dt>CASH</dt>
  <dd i18n>Наличные в РКЦ или почтальону</dd>
  <dt>CASHIN</dt>
  <dd i18n>Наличные в платежно-справочных терминалах</dd>
  <dt>ECASH</dt>
  <dd i18n>Электронные деньги через их покупку наличными</dd>
  <dt>EMXXXXXXX</dt>
  <dd i18n>Электронные деньги (ХХХХХХХ - наименование электронных денег)</dd>
  <dt>PHONE</dt>
  <dd i18n>Телефон</dd>
  <dt>BANKXXX</dt>
  <dd i18n>Клиент идентифицирован банком (ХХХ - УНУР для банка)</dd>
  <dt>ACCOUNT</dt>
  <dd i18n>Банковский счет</dd>
  <dt>BELQI</dt>
  <dd i18n>Электронные деньги Belqi</dd>
  <dt>CASHOSMP</dt>
  <dd i18n>Наличными в терминалах для покупки электронных денег</dd>
  <dt>IPAY</dt>
  <dd i18n>Электронные деньги iPay</dd>
  <dt>WM</dt>
  <dd i18n>Электронные деньги WebMoney (без авторизации в личном кабинете)</dd>
  <dt>WMID</dt>
  <dd i18n>Электронные деньги WebMoney (с авторизацией в личном кабинете)</dd>
  <dt>total</dt>
  <dd i18n>Итого</dd>
  <!--payments-grid => titleAuthorizationType END -->

  <!--unloadAllFileService => unloadAllHeadersTemplate START -->
  <dt>accountNumber</dt>
  <dd i18n>Номер счета</dd>
  <dt>bankCode</dt>
  <dd i18n>Код банка</dd>
  <dt>paymentDocumentNumber</dt>
  <dd i18n>№ платеж. документа</dd>
  <dt>paymentDocumentDate</dt>
  <dd i18n>Дата платеж. документа</dd>
  <dt>paymentDate</dt>
  <dd i18n>Дата оплаты</dd>
  <dt>refundAvailableSum</dt>
  <dd i18n>Доступная для возврата сумма</dd>
  <dt>refundSum</dt>
  <dd i18n>Сумма фактического возврата</dd>
  <dt>commissionSum</dt>
  <dd i18n>Сумма комиссии</dd>
  <dt>transferSum</dt>
  <dd i18n>Сумма зачисления</dd>
  <dt>claimId</dt>
  <dd i18n>Код в системе</dd>
  <dt>paymentId</dt>
  <dd i18n>Код платежа</dd>
  <dt>providerOperId</dt>
  <dd i18n>ID платежа ЕРИП</dd>
  <dt>authorizationType</dt>
  <dd i18n>Способ авторизации суммы</dd>
  <dt>numberPU</dt>
  <dd i18n>Номер ПУ</dd>
  <dt>prevReg</dt>
  <dd i18n>Предыдущее показание</dd>
  <dt>paidIndication</dt>
  <dd i18n>Оплаченное показание</dd>
  <dt>tariff</dt>
  <dd i18n>Тариф</dd>
  <dt>nameDevice</dt>
  <dd i18n>Наименование ПУ</dd>
  <dt>serviceProvision</dt>
  <dd i18n>Услуга оказана</dd>
  <dt>serviceProvisionDecline</dt>
  <dd i18n>Услуга не оказана</dd>
  <dt>serviceProvisionAll</dt>
  <dd i18n>Все</dd>

  <!-- unloadAllFileService => unloadAllHeadersTemplate END -->

  <!--paymentsUnloadStatementFileService => unloadStatementHeadersTemplate START -->
  <dt>fioPayer</dt>
  <dd i18n>ФИО плательщика</dd>
  <dt>addressPayer</dt>
  <dd i18n>Адрес плательщика</dd>
  <dt>paidBYN</dt>
  <dd i18n>Оплачено (BYN)</dd>
  <dt>paymentDocumentDateBank</dt>
  <dd i18n>Дата по банку</dd>
  <!-- paymentsUnloadStatementFileService => unloadStatementHeadersTemplate END -->

  <!--refunds-grid-header => unloadAllHeadersTemplate START -->
  <dt>serviceName</dt>
  <dd i18n>Наименование услуги</dd>
  <dt>messageNumber</dt>
  <dd i18n>Номер сообщения</dd>
  <dt>messageCreationDate</dt>
  <dd i18n>Дата формирования сообщения</dd>
  <dt>paymentOrderNumber</dt>
  <dd i18n>Номер платежного документа</dd>
  <dt>paymentOrderCreationDate</dt>
  <dd i18n>Дата платежного документа</dd>
  <dt>clientAccountNumber</dt>
  <dd i18n>Лицевой счет</dd>
  <dt>bankMFO</dt>
  <dd i18n>Код банка</dd>
  <dt>fio</dt>
  <dd i18n>ФИО</dd>
  <dt>paymentPurpose</dt>
  <dd i18n>Дополнительная информация</dd>
  <dt>refundAmount</dt>
  <dd i18n>Сумма возврата</dd>
  <dt>status</dt>
  <dd i18n>Статус возврата</dd>
  <!-- refunds-grid-header => unloadAllHeadersTemplate END -->

  <!--payments-refund-report-popup or payment-cancel-refund-report-popup => reportFields START -->
  <dt>returnDate</dt>
  <dd i18n>Дата возврата</dd>
  <dt>paymentCode</dt>
  <dd i18n>Код платежа</dd>
  <dt>summa</dt>
  <dd i18n>Сумма</dd>
  <dt>sendOrganization</dt>
  <dd i18n>Организация отправителя</dd>
  <dt>paymentOrderData</dt>
  <dd i18n>Платежное поручение по возвращаемому платежу</dd>
  <dt>paymentReceiver</dt>
  <dd i18n>Получатель денежных средств</dd>
  <dt>paymentOrderDate</dt>
  <dd i18n>Дата</dd>
  <dt>accNum</dt>
  <dd i18n>Лицевой счет</dd>
  <dt>bigBank</dt>
  <dd i18n>БИК банка</dd>
  <!-- payments-refund-report-popup or payment-cancel-refund-report-popup => reportFields END -->

  <!-- Filter Status STAR -->
  <dt>allStatus</dt>
  <dd i18n>Все</dd>
  <dt>preparedStatus</dt>
  <dd i18n>Подготовленные</dd>
  <dt>paidStatus</dt>
  <dd i18n>Оплачено</dd>
  <dt>partiallyPaidStatus</dt>
  <dd i18n>Оплачено частично</dd>
  <dt>notPaidOnTimeStatus</dt>
  <dd i18n>Не оплачено в срок</dd>
  <dt>registeredPaymentAwaitedStatus</dt>
  <dd i18n>Зарегистрировано, ожидается оплата</dd>
  <dt>successfullyReversedStatus</dt>
  <dd i18n>Успешно сторнировано</dd>
  <dt>refundStartedStatus</dt>
  <dd i18n>Возврат начат</dd>
  <dt>refundCompletedStatus</dt>
  <dd i18n>Возврат выполнен</dd>
  <dt>awaitRefundCancelStatus</dt>
  <dd i18n>Ожидание отмены возврата</dd>
  <dt>refundCanceledStatus</dt>
  <dd i18n>Возврат отменен</dd>
  <dt>errorProcessingReturnStatus</dt>
  <dd i18n>Ошибка обработки возврата</dd>
  <dt>registeredStatus</dt>
  <dd i18n>Зарегистрирован</dd>
  <dt>awaitConfirmStatus</dt>
  <dd i18n>Ожидает подтверждения</dd>
  <dt>awaitClientRegistrationStatus</dt>
  <dd i18n>Ожидает регистрации клиента</dd>
  <dt>votedStatus</dt>
  <dd i18n>Подтвержден</dd>
  <dt>allMessagesStatus</dt>
  <dd i18n>Все сообщения</dd>
  <dt>unreadMessagesStatus</dt>
  <dd i18n>Непрочитанные сообщения</dd>
  <dt>blockedStatus</dt>
  <dd i18n>Заблокирован</dd>
  <dt>unlockedStatus</dt>
  <dd i18n>Разблокирован</dd>
  <!-- Filter Status END -->

  <!-- Filter Date STAR -->
  <dt>transferDate</dt>
  <dd i18n>Дата перечисления</dd>
  <dt>paymentAcceptanceDate</dt>
  <dd i18n>Дата приема платежа</dd>
  <!-- Filter Status END -->

  <!-- Filter Pay Debets STAR -->
  <dt>benefitOrganizationPayDebets</dt>
  <dd i18n>В пользу организации</dd>
  <dt>fromOrganizationAccountsPayDebets</dt>
  <dd i18n>Дата приема платежа</dd>
  <!-- Filter Pay Debets END -->
</dl>
