import { OnInit, Input, ChangeDetectorRef, OnDestroy, Directive } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Subject } from "rxjs";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";

@Directive()
export class DefaultFormControl<T> extends BoxValueAccessor<T> implements OnInit, OnDestroy {
	@Input() public formControlName?: string;
	@Input() public formControl?: AbstractControl;
	@Input() public displayValidation = true;
	public control?: AbstractControl | null;
	public showError = false;

	protected unsubscribe$$ = new Subject<void>();

	constructor(
		protected readonly changeDetectorRef: ChangeDetectorRef
	) { super(); }

	public ngOnInit(): void {
		if (!this.displayValidation) {
			return;
		}

		if (this.formControl) {
			this.control = this.formControl;
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
