{{ textValue }}
<div (appClickOutside)="onClickOutside()">
	<input type="text" [class.error]="showError" (click)="expand ? collapse() : unfold()" [value]="(selected && selected[1]) || presetData[0][1]" readonly="readonly"
		[attr.class]="expand ? 'expand': null" placeholder="{{placeholder}}">

	<app-icon (click)="expand ? collapse() : unfold()">unfold_more</app-icon>

	<app-list *ngIf="expand" [data]="this.localBehavior ? filteredData : data" (appInput)="input$$.next($event)" (appSelect)="select($event)" [showSearch]="showSearch"
		(appScrolled)="onScrolled()"></app-list>
</div>
