import { take, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Renderer2, ChangeDetectorRef, HostListener, Component } from "@angular/core";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";

@Component({
	template: ""
})
export abstract class MultiSelectBoxControlComponent<T> extends BoxValueAccessor<T> {
	public collapse = true;
	private hide$ = new Subject<void>();

	constructor(
		protected render: Renderer2,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}

	@HostListener("click")
	public onHostClick(): void {
		if (this.collapse) {
			this.expand();
		}
	}

	public expand(): void {
		this.collapse = false;
		this.changeDetectorRef.detectChanges();
		this.scheduleClosure();
	}

	public hide(): void {
		if (!this.collapse) {
			this.collapse = true;
			this.changeDetectorRef.detectChanges();
			this.hide$.next();
		}
	}

	protected scheduleClosure(): void {
		// https://learn.javascript.ru/event-loop#primer-3-delaem-chto-nibud-posle-sobytiya
		// create macro task
		setTimeout(() => {
			this.getNextWindowClick$().pipe(
				take(1),
				takeUntil(this.hide$)
			).subscribe(() => this.hide());
		});
	}

	protected getNextWindowClick$(): Observable<unknown> {
		return new Observable(subscriber => {
			const unsubscribeFn: () => void = this.render.listen(
				"window",
				"click",
				() => {
					unsubscribeFn();
					subscriber.next();
				}
			);
		});
	}
}
