import { Component, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, Type } from "@angular/core";
import { TemplateUtil } from "@helper/template.util";

@Injectable()
export class ConstantsService {
	private constants = [];

	constructor(
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		private readonly injector: Injector
	) { }

	public addConstants(constantComponents: Type<Component>[]): void {
		if (constantComponents) {
			this.createConstant(constantComponents);
		} else {
			throw new Error("Not 'types' nor 'features' provided.");
		}
	}

	public getConstant(constantName: string): string | undefined {
		const child = this.constants.find(e => e[0] === constantName);

		if (!child) {
			console.error(`Can't find ${constantName}`);
			return undefined;
		}

		return child[1];
	}

	private createConstant(featureTypes: Type<Component>[]): void {
		featureTypes
			.forEach(type => {
				const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);
				const componentRef = componentFactory.create(this.injector);
				const node = (componentRef.hostView as EmbeddedViewRef<Type<Component>>).rootNodes[0];
				const constants = TemplateUtil.getArray(node);

				componentRef.destroy();

				this.constants = [...this.constants, ...constants];
			});
	}
}
