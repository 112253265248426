import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "../services/configuration.service";
import { MessageResponseParams, MessagesResponseDto, ChangeMessageStatusRequest } from "@helper/types/messages-dto";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class Messages {
	public get$(params: MessageResponseParams): Observable<MessagesResponseDto> {
		const url = `${this.config.api.root}/corporation/messages`;

		let httpParams = new HttpParams();

		httpParams = httpParams.append("dateFrom", params.dateFrom.toISOString());
		httpParams = httpParams.append("dateTo", params.dateTo.toISOString());
		httpParams = httpParams.append("status", params.status.toString());

		return this.http.get<MessagesResponseDto>(url, { params: httpParams, withCredentials: true }).pipe(
			map(messagesDto => messagesDto.map(messageDto => {
				const pDateTime = messageDto.date && (messageDto.date as any).split(/\.|:|\s+/); // "21.02.2021 22:45:56".split(/\.|:|\s+/) -> ["21", "02", "2021", "22", "45", "56"]
				return {
					...messageDto,
					date: pDateTime && new Date(pDateTime[2], pDateTime[1] - 1, pDateTime[0], pDateTime[3], pDateTime[4], pDateTime[5]),
				};
			}))
		);
	}

	public post$(messageIds: number[], status: "1" | "0" | "2" | "-2"): Observable<void> {
		const messagesRequest: ChangeMessageStatusRequest[] = [];
		messageIds.forEach(messageId => messagesRequest.push({ messageId, status }));
		const url = `${this.config.api.root}/corporation/messages`;
		return this.http.post<void>(url, messagesRequest, { withCredentials: true });
	}

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
