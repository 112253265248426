import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { User, UserBlock, UserChangePasswordParams, UsersList, UsersSortParams, UserTypes } from "@helper/types/user";
import { Observable } from "rxjs";
import { ConfigurationService } from "../services/configuration.service";

export class Admin {
	// public clientId = {
	// 	get$: (unn: string): Observable<any> => {
	// 		const url = `${this.config.api.admin}/suppliers/unn/${unn}`;
	// 		return this.http.get(url, { withCredentials: true });
	// 	}
	// };

	public admins = {
		get$: (sortParams: UsersSortParams): Observable<UsersList> => {
			const url = `${this.config.api.admin}/clients`;
			let httpParams = new HttpParams();
			if (sortParams) {
				httpParams = httpParams.append("sort", `${sortParams.column},${sortParams.direction}`);
			}
			return this.http.get<UsersList>(url, { withCredentials: true, params: httpParams });
		}
	};

	public createUser = {
		post$: (userData: User): Observable<void> => {
			const url = `${this.config.api.admin}/clients`;
			return this.http.post<void>(url, userData, { withCredentials: true });
		}
	};

	public deleteUser = {
		delete$: (userId: number): Observable<void> => {
			const url = `${this.config.api.admin}/clients/${userId}`;
			return this.http.delete<void>(url, { withCredentials: true });
		}
	};

	public deleteUsers = {
		delete$: (ids: number[]): Observable<string> => {
			const url = `${this.config.api.admin}/clients`;
			const options = {
				responseType: "text" as "json",
				body: ids,
				withCredentials: true
			};
			return this.http.delete<string>(url, options);
		}
	};

	public editUser = {
		post$: (userData: User): Observable<void> => {
			const url = `${this.config.api.admin}/clients/${userData.id}`;
			return this.http.put<void>(url, userData, { withCredentials: true });
		}
	};

	public changePassword = {
		patch$: (params: UserChangePasswordParams): Observable<void> => {
			const url = `${this.config.api.admin}/clients`;
			return this.http.patch<void>(url, params, { withCredentials: true });
		}
	};

	public resetUserPassword = {
		patch$: (userId: number): Observable<void> => {
			const url = `${this.config.api.admin}/clients/${userId}`;
			return this.http.patch<void>(url, null, { withCredentials: true });
		}
	};

	public blockUser = {
		patch$: ({ id, blocked }: { id: number, blocked: boolean }): Observable<void> => {
			const url = `${this.config.api.admin}/clients/${id}`;
			let headers = new HttpHeaders();
			headers = headers.set("Content-Type", "application/json");
			return this.http.patch<void>(url, blocked, { withCredentials: true, headers });
		}
	};

	public blockUsers = {
		put$: (userData: UserBlock): Observable<void> => {
			const url = `${this.config.api.admin}/clients`;
			let headers = new HttpHeaders();
			headers = headers.set("Content-Type", "application/json");
			return this.http.put<void>(url, userData, { withCredentials: true, headers });
		}
	};

	public clientTypes = {
		get$: (): Observable<UserTypes> => {
			const url = `${this.config.api.admin}/clients/types`;
			return this.http.get<UserTypes>(url, { withCredentials: true });
		}
	};

	constructor(private readonly config: ConfigurationService, private readonly http: HttpClient) { }
}
