import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "@core/services/configuration.service";
import { PaymentsUtil } from "@helper/payments.util";
import { Payment, PaymentRefundParams } from "@helper/types/payment";
import { PaymentCancelRefundReportDto, PaymentsDto } from "@helper/types/payment-dto";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaymentsFilterFormValue } from "src/app/layout/payments/services/payments-filter-form-builder.service";

export class Payments {
	// private paymentsUti = PaymentsUtil;

	public refund = {
		post$: (data: PaymentRefundParams): Observable<void> => {
			const url = `${this.config.api.root}/corporation/payments/${data.refundPayment.value}`;
			return this.http.post<void>(url, data, { withCredentials: true });
		},
	};

	public cancelRefund = {
		put$: (paymentId: number): Observable<PaymentCancelRefundReportDto> => {
			const url = `${this.config.api.root}/corporation/payments/${paymentId}`;
			return this.http.put<PaymentCancelRefundReportDto>(url, null, { withCredentials: true });
		},
	};

	public changePaymentStatus = {
		get$: (paymentId: number, status: number): Observable<void> => {
			const url = `${this.config.api.root}/corporation/services/provision?action=${status}&paymentId=${paymentId}`;
			return this.http.get<void>(url, { withCredentials: true });
		},
	};

	public banks = {
		get$: (): any => {
			const url = `${this.config.api.root}/corporation/dictionaries/BANK`;
			return this.http.get(url, { withCredentials: true });
		},
	};

	public get$(filter: PaymentsFilterFormValue): Observable<Payment[]> {
		const url = `${this.config.api.root}/corporation/payments`;

		let params = new HttpParams();
		params = params.append("dateFrom", moment(filter.period[0]).format("DD.MM.yyyy") + " " + "00:00:00");
		params = params.append("dateTo", moment(filter.period[1]).format("DD.MM.yyyy") + " " + "23:59:59");
		params = params.append("serviceCode", filter.service.toString());
		params = params.append("dateType", filter.dateType.toString());
		if (filter.claim) {
			params = params.append("claimId", filter.claim.toString());
		}
		if (filter.status) {
			params = params.append("status", filter.status.toString());
		}
		if (typeof filter.payDebet === "number") {
			params = params.append("payDebet", filter.payDebet.toString());
		}

		return this.http.get<PaymentsDto>(url, { params, withCredentials: true }).pipe(
			map((paymentsDto) =>
				paymentsDto.map((paymentDto) => {
					return PaymentsUtil.dtoToPayment(paymentDto);
				})
			)
		);
	}

	constructor(private config: ConfigurationService, private http: HttpClient) {}
}
