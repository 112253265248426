import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { environment } from "../../../environments/environment";

/*
	Need to pass required project in data object of route
*/
@Injectable()
export class ProjectGuard {
	public canActivate(route: ActivatedRouteSnapshot): boolean {
		const project = route.data.project;
		return project === environment.project;
	}

}
