import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, Type } from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ConstantsService } from "@core/constants/constants.service";
import { Subscription } from "rxjs";
import { MultiSelectBoxControlComponent } from "./multi-select-box-control";
export type ListItem<T> = [T | null, string];

@Component({
	selector: "app-multi-select-box",
	providers: [{
		multi: true,
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(<T>(): Type<MultiSelectBoxComponent<T>> => MultiSelectBoxComponent)
	}],
	templateUrl: "./multi-select-box.component.html",
	styleUrls: ["./multi-select-box.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MultiSelectBoxComponent<T> extends MultiSelectBoxControlComponent<T[]> implements OnChanges, OnInit, OnDestroy {
	@Input() public multiChecked = false;
	@Input() public isSingle = false;
	@Input() public isEditable = false;
	@Input() public isSearch = false;
	@Input() public isCheckAll = false;
	@Input() public isBlockAdding = true;
	@Input() public label?: string;
	@Input() public data?: ListItem<T>[];
	@Input() public checkStateList?: { id: number; checkedState: boolean | undefined, disabled?: boolean }[] = [];
	@Input() public multiplyCheckedDisable?: { id: number; disabled: boolean | undefined }[] = [];
	@Input() public formControlName?: string;
	@Input() public formControl?: AbstractControl;

	@Output() public addRecord: EventEmitter<void> = new EventEmitter();
	@Output() public onCheck: EventEmitter<number[]> = new EventEmitter();
	@Output() public deleteRecord: EventEmitter<any> = new EventEmitter();
	@Output() public highlightTreeCheck: EventEmitter<{ isChecked: boolean; checked: any }> = new EventEmitter();
	@Input() public set isDisabled(value: boolean) { this.disabled = value; }
	@Input() public set setLable(value: string) { this.label = this.constantsService.getConstant(value); }
	public highlightTree: boolean | undefined = false;
	public isShowError = false;
	public error = false;
	public disabled = false;
	private searchValue = "";
	public control?: AbstractControl | null;
	public filteredData?: ListItem<T>[] = [];
	public allNameValue: any[] = [];
	public checked: ListItem<T>[] = [];
	public get displayingData() {
		return this.searchValue && this.filteredData || this.data;
	}

	public get displayAllName() {
		return this.isCheckAll && this.allNameValue.length === this.data.length ? this.constantsService.getConstant("allLable") : this.allNameValue.join(", ");
	}
	private subscription?: Subscription;

	constructor(
		render: Renderer2,
		changeDetectorRef: ChangeDetectorRef,
		private constantsService: ConstantsService,
		private controlContainer: ControlContainer
	) {
		super(render, changeDetectorRef);
	}

	public ngOnInit(): void {
		this.setControl();
		this.checkAll(this.isCheckAll);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.value) {
			this.checked = this.data?.filter((item: any) => this.value?.includes(item[0])) || [];
			this.allNameValue = this.data?.filter((item: any) => this.value?.includes(item[0]))?.map(element => element[1]) || [];
		}
		if ("checkStateList" in changes && this.isSingle && this.value) {
			this.highlightTree = this.getCheckedState(this.value[0]);
		}
		this.checkAll(this.isCheckAll);
	}

	public setControl(): void {
		this.control = undefined;
		if (this.formControl) {
			this.control = this.formControl;
		}

		if (this.controlContainer && this.formControlName && this.controlContainer.control) {
			this.control = this.controlContainer.control.get(this.formControlName);
		}
		this.subscription?.unsubscribe();
		this.subscription = this.control?.statusChanges?.subscribe(() => {
			if (!this.error) {
				this.isShowError = this.control && this.control.touched && this.control.invalid || false;
			} else {
				this.isShowError = this.error;
			}
			this.changeDetectorRef.detectChanges();
		});
	}

	public onClickOutside(): void {
		this.hide();
	}

	public searchHandler(event: Event): void {
		const element = event.target as HTMLInputElement;
		this.searchValue = element.value.toLowerCase();
		this.filteredData = this.data?.filter(e => e[1].toLowerCase().includes(this.searchValue));
	}

	public check(item: ListItem<T>): void {
		if (!this.isSingle && this.multiplyCheckedDisable?.length && item[0] !== null && this.isSetDisabled(item[0]) === true) {
			return;
		}
		let newValue: any;
		if (!this.isSingle) {
			const index = this.checked.findIndex(checkedItem => checkedItem[0] === item[0]);
			if (index === -1) {
				this.checked.push(item);
				this.allNameValue.push(item[1]);
			} else {
				this.checked.splice(index, 1);
				this.allNameValue.splice(index, 1);

			}
			newValue = this.checked.map(c => c[0]);
		} else {
			newValue = item;
			this.hide();
		}
		this.writeValue(newValue);
		if (this.onChange) { this.onChange(newValue); }
		this.changeDetectorRef.detectChanges();
	}

	public checkAll(isCheck: boolean): void {
		if (isCheck) {
			this.checked = (this.multiplyCheckedDisable?.length
				? this.displayingData?.filter(el => !this.isSetDisabled(el[0]))
				: this.displayingData) as any[];
		} else {
			this.checked = [];
		}

		const newValue = this.checked.map(c => c[0]);
		this.writeValue(newValue);
		if (this.onChange) { this.onChange(newValue); }
		this.changeDetectorRef.detectChanges();
	}

	public writeValue(value: any): void {
		this.value = value;
		this.checked = this.data?.filter(item => (value || [])?.includes(item[0])) || [];
		this.allNameValue = this.data?.filter((item: any) => this.value?.includes(item[0]))?.map(element => element[1]) || [];
		this.onCheck.emit(this.value);
	}

	public add(): void {
		this.addRecord.emit();
	}

	public delete(item: ListItem<T>): void {
		this.deleteRecord.emit(item);
	}

	public trackBy(index: number, item: ListItem<T>): string {
		return `${index}${item[1]}`;
	}

	public isChecked(item: ListItem<T>): boolean {
		return this.checked.some(checkedItem => checkedItem[0] === item[0]);
	}

	public isHighlightTree(): void {
		this.highlightTree = this.highlightTree === undefined ? false : !this.highlightTree;
		this.highlightTreeCheck.emit({ isChecked: this.highlightTree, checked: this.checked });
	}

	public isEveryItemsDisabled(): boolean {
		return this.multiplyCheckedDisable ? this.multiplyCheckedDisable.every((el) => el.disabled === true) && !this.multiChecked : false;
	}

	public getCheckedState(id: any): boolean | undefined {
		const el = this.checkStateList?.find(check => check.id === id);
		if (!el) { return false; }
		return el.checkedState;
	}

	public isSetDisabled(id: any | null): boolean | undefined {
		if (!id) {
			throw Error("No item id");
		}
		const el = this.multiplyCheckedDisable?.find((multiplyCheck) => multiplyCheck.id === id);
		if (!el) {
			return true;
		}
		return el.disabled;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled ? isDisabled : null;
	}

	public ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}
	public hide(): void {
		this.searchValue = "";
		super.hide();
	}
}
