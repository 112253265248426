import { ConfigurationService } from "@core/services/configuration.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Claim, QRCodeParams } from "@helper/types/claim";
import { ClaimFilterFormValue } from "src/app/layout/claims/services/claims-filter-form-builder.service";
import { map } from "rxjs/operators";
import { ClaimsUtil } from "@helper/claims.util";
import { NewClaimForm } from "src/app/layout/claims/services/new-claim-form-builder.service";
import { ClaimsDto } from "@helper/types/claim-dto";
import * as moment from "moment";

export class Claims {
	private claimsUtil = ClaimsUtil;

	public qrcode = {
		get$: (p: QRCodeParams): Observable<string> => {
			const url = `${this.config.api.root}/corporation/claims/${p.claimId}/qrcode`;
			let params = new HttpParams();
			params = params.append("accNum", p.payerNumber);
			// params = params.append("id", p.claimId.toString());
			params = params.append("serviceCode", p.serviceCode && p.serviceCode.toString());
			params = params.append("shortName", p.shortName && p.shortName.toString());
			params = params.append("sum", p.sum.toString());

			return this.http.get<string>(url, { params, withCredentials: true });
		}
	};

	public get$(filter: ClaimFilterFormValue): Observable<Claim[]> {
		const url = `${this.config.api.root}/corporation/claims`;

		let params = new HttpParams();
		params = params.append("dateFrom", moment(filter.period[0]).format("DD.MM.yyyy") + " " + "00:00:00");
		params = params.append("dateTo", moment(filter.period[1]).format("DD.MM.yyyy") + " " + "23:59:59");
		params = params.append("serviceCode", filter.service && filter.service.toString());

		if (filter.status) {
			params = params.append("status", filter.status.toString());
		}

		return this.http.get<ClaimsDto>(url, { params, withCredentials: true }).pipe(
			map(claimsDto => claimsDto.map(claimDto => this.claimsUtil.dtoToClaim({ ...claimDto, serviceCode: filter.service, sum: claimDto.sum })))
		);
	}

	public post$(forms: NewClaimForm[]): Observable<void> {
		const url = `${this.config.api.root}/corporation/claims`;
		return this.http.post<void>(url, forms.map(form => this.claimsUtil.claimToDto(form)), { withCredentials: true });
	}

	public delete$(claimsIds: number[]): Observable<void> {
		const url = `${this.config.api.root}/corporation/claims`;
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
			}),
			body: claimsIds,
			withCredentials: true
		};
		return this.http.delete<void>(url, options);
	}

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
