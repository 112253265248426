import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";
import { Async } from "@helper/async.decorator";

@Component({
	selector: "app-qr-code-view",
	templateUrl: "./QR-code-view.component.html",
	styleUrls: ["./QR-code-view.component.scss"]
})
export class QRCodeViewComponent {
	@Input() public dataUrl: string;
	@Input() public headerText: string;
	@Input() public payLink?: string;
	@Input() public debugData: {
		serviceCode: number,
		payerNumber: string,
		sum: number,
		organizationShortName: string
	};
	@Async() public close$ = new Subject<void>();

	public copyLink(): void {
		this.textToClipboard(this.payLink);
	}

	private textToClipboard (text) {
		const dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
	}
}
