import { Component, forwardRef, Type, ChangeDetectionStrategy } from "@angular/core";
import { TextBoxComponent } from "../text-box/text-box.component";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

type DateRage = [Date | null, Date | null];

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<RangeDateBoxComponent> => RangeDateBoxComponent)
	}],
	selector: "app-range-date-box",
	templateUrl: "./range-date-box.component.html",
	styleUrls: ["./range-date-box.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeDateBoxComponent extends TextBoxComponent<DateRage> {
	public collapse = true;

	public selectedRange(range?: [Date, Date]): void {
		if (Array.isArray(range)) {
			this.writeValue(range);
			if (this.onChange) { this.onChange(range); }
			if (this.onTouched) { this.onTouched(range); }
		}
		this.collapse = true;
	}
}
