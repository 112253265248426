import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../environments/environment";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	public env = environment;
	public titleNames = [
		["vesta-front", "Система выставления требований"],
		["nkfo-front", "Кабинет производителя услуг"]
	];
	constructor(private readonly titleService: Title) { }

	public ngOnInit(): void {
		let title = "";
		if (this.env?.project === "nkfo-front") {
			title = this.titleNames.find(el => el[0] === this.env?.project)[1];
		}
		if (this.env?.project === "vesta-front") {
			title = this.titleNames.find(el => el[0] === this.env?.project)[1];
		}
		this.titleService.setTitle(title);
	}
}
