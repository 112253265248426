import { CreateRefundRegister, EditRefundRegister } from "./../../helper/types/refund";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "@core/services/configuration.service";
import { Refund, Register } from "@helper/types/refund";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RefundsFilterFormValue } from "src/app/layout/refunds/service/refunds-filter-form-builder.service";

export class Refunds {
	public register = {
		get$: (filter: RefundsFilterFormValue): Observable<Register[]> => {
			const url = `${this.config.api.root}/refund/register`;
			let params = new HttpParams();
			params = params.append("dateFrom", moment(filter.period[0]).format("DD.MM.yyyy") + " " + "00:00:00");
			params = params.append("dateTo", moment(filter.period[1]).format("DD.MM.yyyy") + " " + "23:59:59");
			params = params.append("serviceCode", filter.service.toString());
			params = params.append("status", filter.status.toString());
			return this.http.get<{ registers: Register[] }>(url, { params, withCredentials: true }).pipe(
				map(data => {
					if (!data.registers) {
						return [];
					}
					return data.registers.map(register => {
						const paymentOrderCreationDate = register.paymentOrderCreationDate && moment(register.paymentOrderCreationDate, "DD.MM.YYYY HH:mm:ss").format();
						const messageCreationDate = register.messageCreationDate && moment(register.messageCreationDate, "DD.MM.YYYY HH:mm:ss").format();
						return {
							...register,
							paymentOrderCreationDate: paymentOrderCreationDate && new Date(paymentOrderCreationDate),
							messageCreationDate: messageCreationDate && new Date(messageCreationDate)
						};
					});
				})
			);
		}
	};

	public cancelRefundRecords = {
		delete$: (registerId: number): Observable<void> => {
			const url = `${this.config.api.root}/refund/cancelRefundRecords/${registerId}`;
			return this.http.delete<void>(url, { withCredentials: true });
		}
	};

	public get$(service: number, registerIds?: number[]): Observable<Refund[]> {
		const url = `${this.config.api.root}/refund`;
		let params = new HttpParams();
		params = params.append("serviceCode", service.toString());
		if (registerIds) {
			params = params.append("registerIds", registerIds.toString());
		}
		return this.http.get<{ refunds: Refund[] }>(url, { params, withCredentials: true }).pipe(map(data => data.refunds ? data.refunds : []));
	}

	public put$(value: EditRefundRegister) {
		const url = `${this.config.api.root}/refund/${value.registerId}`;
		return this.http.put(url, value, { withCredentials: true });
	}

	public post$(value: CreateRefundRegister): Observable<void> {
		const url = `${this.config.api.root}/refund`;
		return this.http.post<void>(url, value, { withCredentials: true });
	}

	public delete$(refundPaymentIds: number[], registerId?: number): Observable<void> {
		let params = new HttpParams();
		params = params.append("refundPaymentIds", refundPaymentIds.toString());
		if (registerId) {
			params = params.append("registerId", registerId.toString());
		}
		const url = `${this.config.api.root}/refund`;
		return this.http.delete<void>(url, { params, withCredentials: true });
	}

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
