import { Injectable } from "@angular/core";
import { PlatformLocation } from "@angular/common";
import { environment } from "../../../environments/environment";

const languages: string[] = ["ru", "en"];

@Injectable({
	providedIn: "root"
})
export class LanguageService {
	private env = environment;
	private baseLang: string;

	constructor(
		private platformLocation: PlatformLocation
	) {
		const baseHref = this.platformLocation.getBaseHrefFromDOM();
		if (this.env?.isChangeLang) {
			this.baseLang = baseHref.split("/")[1];
			const isLang = languages.filter(data => data === this.baseLang).length > 0;
			localStorage.setItem("Language", isLang ? this.baseLang : "ru");
		}
	}

	public getLanguage(): string {
		return localStorage.getItem("Language") || "ru";
	}
}
