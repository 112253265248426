import { AlgorithmListRequest } from "@helper/types/service";
import { AccountStatementFilter } from "@helper/types/accounts";

export class GetProfile {
	public static readonly type = "[Layout] Get Profile";
}

export class GetAlgorithmList {
	public static readonly type = "[Layout] Get Algorithm List";
	constructor(public id: number) { }
}

export class GetRefundsWithoutRegister {
	public static readonly type = "[Layout] Get Refunds Without Register";
	constructor(public serviceId: number) { }
}

export class ResetAlgorithmList {
	public static readonly type = "[Layout] Reset Algorithm List";
}

export class AddAlgorithmList {
	public static readonly type = "[Layout] Add Algorithm List";
	constructor(public serviceCode: number, public algorithm: AlgorithmListRequest) { }
}

export class EditAlgorithmList {
	public static readonly type = "[Layout] Edit Algorithm List";
	constructor(public serviceCode: number, public algorithm: AlgorithmListRequest) { }
}

export class DeleteAlgorithmList {
	public static readonly type = "[Layout] Delete Algorithm List";
	constructor(public serviceCode: number, public algorithmId: number) { }
}
export class GetClientId {
	public static readonly type = "[Layout] Get Client Id";
	constructor(public unn: string) { }
}

export class RefreshServices {
	public static readonly type = "[Layout] Refresh Services";
}

export class Reset {
	public static readonly type = "[Layout] Reset";
}

export class GetAccountStatement {
	public static readonly type = "[Layout] Get Account Statement";
	constructor(public filter: AccountStatementFilter) { }
}

export class ResetAccountStatement {
	public static readonly type = "[Layout] Reset Account Statement";
}

export class DeleteRefundWithoutRegister {
	public static readonly type = "[Layout] Delete Refund Without Register";
	constructor(public refundPaymentId: number[]) { }
}
