import { Component, Input } from "@angular/core";

@Component({
	selector: "app-confirm-delete-claims",
	templateUrl: "./confirm-delete-claims.component.html",
	styleUrls: ["./confirm-delete-claims.component.scss"]
})
export class ConfirmDeleteClaimsComponent {
	@Input() public selected?: number;
	@Input() public deletedClaims?: number;
}
