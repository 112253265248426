
import { Injectable } from "@angular/core";
import { NavigatorService } from "@core/services/navigator.service";
import { AuthorizationService } from "@core/services/authorization.service";

@Injectable()
export class AuthorizationGuard {
	constructor(
		private navigatorService: NavigatorService,
		private authorizationService: AuthorizationService
	) { }

	public async canActivate(): Promise<boolean> {
		if (!this.authorizationService.isAuthorized) {
			this.navigatorService.goLoginPage();
		}
		return this.authorizationService.isAuthorized;
	}
}
