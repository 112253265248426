import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
	public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const req = httpRequest.clone({
			headers: httpRequest.headers
				.set("Accept-Language", localStorage.getItem("Language") || "ru")
		});
		return next.handle(req);
	}
}
