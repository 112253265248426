import { ConfigurationService } from "@core/services/configuration.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CreateUserParams, CreateUserResponse, EditUserParams, EditUserResponse, LoginUserParams, LoginUserResponse } from "@helper/types/registration";

export class Registration {
	public editUser = {
		post$: (params: EditUserParams): Observable<EditUserResponse> => {
			return this.http.post<EditUserResponse>(`${this.config.api.root}/registration/edituser`, params);
		}
	};

	public loginUser = {
		post$: (params: LoginUserParams): Observable<LoginUserResponse> => {
			return this.http.post<LoginUserResponse>(`${this.config.api.root}/registration/login`, params);
		}
	};

	public createUser = {
		post$: (params: CreateUserParams): Observable<CreateUserResponse> => {
			return this.http.post<CreateUserResponse>(`${this.config.api.root}/registration/create`, params);
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
