import { ChangeDetectionStrategy, Component, forwardRef, Type } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextBoxComponent } from "@shared/controls/text-box/text-box.component";

@Component({
	selector: "app-password-box",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<PasswordBoxComponent> => PasswordBoxComponent)
	}],
	template: `<div class="password">
      <input #input type="password" [value]="value" (input)="onInput(input.value)" [type]="showPassword ? 'text' : 'password'" placeholder="{{placeholder}}">
				<app-icon *ngIf="!showPassword" (click)="showPassword = !showPassword">visibility</app-icon>
				<app-icon *ngIf="showPassword" (click)="showPassword = !showPassword">visibility_off</app-icon>
		</div>`,
	styleUrls: ["./password-box.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordBoxComponent extends TextBoxComponent {
	public showPassword = false;
}
