import {
	ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges
} from "@angular/core";
import { TextUtil } from "@helper/text-util";

@Component({
	selector: "app-select-date-range",
	templateUrl: "./select-date-range.component.html",
	styleUrls: ["./select-date-range.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDateRangeComponent implements OnChanges {
	@Input() public value: [Date | null, Date | null] = [null, null];
	@Output() public appClose = new EventEmitter<[Date, Date] | void>();
	public today = new Date();
	private picker: 1 | 2 = 1;

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.value && !Array.isArray(this.value)) {
			this.value = [null, null];
		}
	}

	@HostListener("window:keyup", ["$event"])
	public onEnter(event: any): void {
		if (event.target && event.keyCode === 13) {
			this.setRange(this.value[0], this.value[1]);
		}
	}

	public onValueChange(value: [Date | null, Date | null], picker: 1 | 2): void {
		if (this.picker !== picker && +this.value[0] === +this.value[1] && +value[0] === +value[1]) {
			const pv = +this.value[0]; // предыдущее значение
			const cv = +value[0]; // текущее значение
			this.value = pv < cv ? [new Date(pv), new Date(cv)] : [new Date(cv), new Date(pv)];
		} else {
			this.value = value;
		}
		this.picker = picker;
	}

	public setRange(startValue: Date, endValue: Date): void {
		const start = new Date(this.getDateString(startValue) + " 00:00:00");
		const end = new Date(this.getDateString(endValue) + " 23:59:59");
		this.value = [start, end];
		this.appClose.emit(this.value);
	}

	private getDateString(date: Date): string {
		return TextUtil.toFourSymbol(date.getFullYear()) + "-" +
			TextUtil.toTwoSymbol(date.getMonth() + 1) + "-" + TextUtil.toTwoSymbol(date.getDate());
	}
}
