import { ChangeDetectionStrategy, Component, forwardRef, Input, Type } from "@angular/core";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-textarea",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<TextareaComponent> => TextareaComponent)
	}],
	template: `
				<textarea #textarea [attr.disabled]="disabled" [attr.maxlength]="maxLength" [value]="!value ? '' : value" (input)="onInput(textarea.value)" [attr.rows]="rows"></textarea>
	`,
	styleUrls: ["./textarea.component.scss", "../controls.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class TextareaComponent<T = string> extends BoxValueAccessor<T> {
	@Input() public maxLength?: number;
	@Input() public rows = 3;
	public disabled: "" | null = null;

	public onInput(value: T): void {
		this.writeValue(value);
		if (this.onChange) { this.onChange(value); }
		if (this.onTouched) { this.onTouched(value); }
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled ? "" : null;
	}
}
