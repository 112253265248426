import { ɵComponentDef as ComponentDef } from "@angular/core";

const componentDefKey = "ɵcmp";
type Mutable<T> = { -readonly [P in keyof T]: T[P] };

export function Async<T>(): PropertyDecorator {
	return function (target: T, propertyName: string): void {
		const componentDef: Mutable<ComponentDef<T>> = target.constructor[componentDefKey];
		(componentDef as any).onDestroy = function (this: T) {
			if ((componentDef as any)?.onDestroy()) {
				(componentDef as any)?.onDestroy().call(this);
			}
			const property = target[propertyName];
			if (property) {
				if (typeof property.unsubscribe === "function") {
					property.unsubscribe();
				}

				if (typeof property.complete === "function") {
					property.complete();
				}
			}
		};
	};
}
