/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, forwardRef, Type, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";

const maskConfig = { _: "[0-9]", a: "[a-z]" };

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<MaskedTextBoxComponent<string>> => MaskedTextBoxComponent)
	}],
	selector: "app-masked-text-box",
	template: `<input #t [value]="!value ? '' : value" (input)="handleEvent($event)" type="text"
                    (focus)="handleEvent($event)" [disabled]="isDisabled" (paste)="onPaste($event)">`,
	styleUrls: ["../text-box/text-box.component.scss", "../controls.scss"]
})
export class MaskedTextBoxComponent<T = string> extends BoxValueAccessor<string> implements OnChanges {
	@Input() public mask?: string;
	@Input() public isDisabled = false;

	constructor() {
		super();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.mask.previousValue) {
			if (!changes.mask.currentValue) {
				this.value = null;
			} else {
				this.value = changes.mask.currentValue;
			}
			this.writeValue(this.value);
			if (this.onChange) { this.onChange(this.value); }
			if (this.onTouched) { this.onTouched(this.value); }
		}
	}

	public onPaste(event: ClipboardEvent): void {
		let mask = this.mask;

		this.mask.match(/(_)+/gm).forEach(find => {
			mask = mask.replace(find, `[\\d]{${find.length}}`);
		});

		const isMatch = (new RegExp(mask)).test(event.clipboardData.getData("text"));
		if (!isMatch) {
			this.value = this.mask;
		} else {
			this.value = event.clipboardData.getData("text");
		}
		this.writeValue(this.value);
		if (this.onChange) { this.onChange(this.value); }
		if (this.onTouched) { this.onTouched(this.value); }
		event.preventDefault();
		event.stopPropagation();
	}

	public handleEvent(event: Event): void {
		let value = (event.target as any).value;
		if (event.type === "focus" && (event.target as any).value !== "") {
			return;
		}
		let saved;
		const msk = this.mask.split("");
		const mskd = [];
		let s = (event.target as any).selectionStart - 1;
		msk.forEach((el, n) => {
			if (maskConfig[el]) {
				const t = new RegExp(maskConfig[el], "i").test(value.charAt(n));
				mskd.push(t ? value.charAt(n) : saved ? saved : "_");
				saved = null;
				if (t && s === n && value.charAt(n) !== "_") {
					s++;
				}
			} else {
				if (el !== value.charAt(n)) {
					saved = value.charAt(n);
					value = value.substr(0, n) + value.substr(n + 1, value.length);
				}
				mskd.push(el);
				if (s === n) {
					s++;
				}
			}
		});

		this.value = mskd.join("");
		// this.value providing by ControlValueAccessor doesn't delete last character in input (parsed value length < input value length)
		(event.target as any).value = this.value;

		this.writeValue(this.value);
		if (this.onChange) { this.onChange(this.value); }
		if (this.onTouched) { this.onTouched(this.value); }


		setTimeout(() => {
			this.setCursor(s < 0 ? this.mask.indexOf(Object.keys(maskConfig)[0]) : s, event.target);
		}, 0);
		this.setCursor(s < 0 ? this.mask.indexOf(Object.keys(maskConfig)[0]) : s, event.target);
	}

	public setCursor(pos: number, element: any): void {
		element.focus();
		if (element.setSelectionRange) {
			element.setSelectionRange(pos, pos);
		}
	}
}
