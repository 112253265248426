import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, LOCALE_ID, Type } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextBoxComponent } from "../text-box/text-box.component";
import { formatDate } from "@angular/common";

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<DateBoxComponent> => DateBoxComponent)
	}],
	selector: "app-date-box",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "./date-box.component.html",
	styleUrls: ["./date-box.component.scss", "../controls.scss"]
})
export class DateBoxComponent extends TextBoxComponent<Date | string> {
	public collapse = true;
	public get showedValue() {
		if (this.value instanceof Date && !isNaN(this.value.getDate())) {
			return formatDate(this.value, "dd.MM.yyyy", this.locale);
		} else {
			return this.value;
		}
	}

	public get selectedValue() {
		if (this.value instanceof Date && !isNaN(this.value.getDate())) {
			return this.value;
		} else {
			return undefined;
		}
	}

	constructor(@Inject(LOCALE_ID) private locale, private readonly changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	public onFocusOut(event: any): void {
		const value = event.target.value;
		const parsedValue = value.split(".");
		const newDate = new Date(+parsedValue[2], +parsedValue[1] - 1, +parsedValue[0]);
		if (!isNaN(newDate.getDate())) {
			this.selectedDate(newDate);
		} else {
			this.selectedDate(event.target.value);
		}
	}

	public validateInput(event: any): void {
		event.target.value = event.target.value.replace(",", ".");
		event.target.value = event.target.value.replace(/[^\d.]/g, "").slice(0, 10);
		this.onInput(event.target.value);
	}

	public selectedDate(date?: Date): void {
		if (date) {
			this.onInput(date);
		}
		this.collapse = true;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled ? "" : null;
	}
}
