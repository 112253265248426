import { Component, forwardRef, Type, Input, OnInit, HostBinding, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { AbstractControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef((): Type<TextBoxComponent<string>> => TextBoxComponent)
		}
	],
	selector: "app-text-box",
	template: `<input [ngClass]="{disabled_color: disabledColor}" [value]="!value ? '' : value" (input)="onInput($event.target.value)" [type]="inputType === 'password' ?  showPassword ? 'text' : 'password' : 'text'"
	[attr.disabled]="disabled" [attr.maxlength]="maxLength"  placeholder="{{placeholder}}"><ng-container *ngIf="inputType === 'password'"><app-icon (click)="showPassword = !showPassword">{{ showPassword ? 'visibility_off' : 'visibility' }}</app-icon></ng-container>`,
	styleUrls: ["./text-box.component.scss", "../controls.scss"]
})
export class TextBoxComponent<T = string> extends BoxValueAccessor<T> implements OnInit, OnDestroy {
	@Input() public maxLength?: number;
	@Input() public placeholder?: string;
	@Input() public inputType?: "password" | "text" = "text";
	@Input() public formControl: AbstractControl;
	@Input() public disabledColor = false;
	@Input()
	public set isDisabled(value: boolean) {
		this.disabled = value ? "" : null;
	}
	public disabled: "" | null = null;
	public showError = false;
	public showPassword = this.inputType === "password";


	private unsubscribe$$ = new Subject<void>();

	constructor(protected readonly changeDetectorReg: ChangeDetectorRef) { super(); }

	@HostBinding("class.error")
	public get error(): boolean {
		return this.showError;
	}

	public ngOnInit() {
		if (this.formControl) {
			this.formControl.root.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
				this.formControl.updateValueAndValidity({ emitEvent: false });
				this.showError = !!(this.formControl.touched && this.formControl.errors && !this.formControl.dirty);
				this.changeDetectorReg.markForCheck();
			});
		}
	}

	public onInput(value: T): void {
		this.writeValue(value);
		if (this.onChange) { this.onChange(value); }
		if (this.onTouched) { this.onTouched(value); }
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled ? "" : null;
		this.changeDetectorReg.markForCheck();

	}

	public ngOnDestroy() {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
