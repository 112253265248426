export class TemplateUtil {
	public static getMap(element: HTMLElement): Map<any, any> {
		const dl = element.querySelector("dl");
		const dts = dl.querySelectorAll("dt");

		return Array.from(dts).reduce((map, dt): Map<any, any> => {
			const nextDt = dt.nextElementSibling as HTMLElement;

			if (!nextDt || nextDt.tagName !== "DD") {
				throw new Error("Bad template.");
			}

			map.set(this.getElementValue(dt), this.getElementValue(nextDt));
			return map;
		}, new Map<any, any>());
	}

	public static getArray(element: HTMLElement): [any, string][] {
		const dl = element.querySelector("dl");
		const dts = dl.querySelectorAll("dt");

		return Array.from(dts).map(dt => {
			const nextDt = dt.nextElementSibling as HTMLElement;

			if (!nextDt || nextDt.tagName !== "DD") {
				throw new Error("Bad template.");
			}

			return [this.getElementValue(dt), this.getElementValue(nextDt)];
		});
	}

	public static getElementValue(element: HTMLElement): any {
		if (element.hasAttribute("json")) {
			return JSON.parse(element.innerText.trim());
		}

		return element.innerText.trim();
	}
}

export class ObjectMappingUtil {
	public static isEveryFieldNull(obj: any): boolean {
		if (!obj) {
			return true;
		}
		return Object.values(obj).every(value => value === null || value === "");
	}

	public static isEveryFieldNullOrEmptyArray(obj: any): boolean {
		return Object.values(obj).every(value =>
			value === null ||
			value === "" ||
			(Array.isArray(value) && value.length === 0) ||
			(typeof value === "object" && this.isEveryFieldNullOrEmptyArray(value))
		);
	}
}
