import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { AuthorizationService } from "../services/authorization.service";
import { NavigatorService } from "@core/services/navigator.service";

/*
	Need to pass required project in data object of route
*/

@Injectable()
export class UserGuard {
	constructor(
		private authorizationService: AuthorizationService,
		private navigatorService: NavigatorService
	) { }

	public canActivate(route: ActivatedRouteSnapshot): boolean {
		if (route.data.userRole === "super" && this.authorizationService.isSuper) {
			return true;
		} else if (Array.isArray(route.data.userRole) && route.data.userRole.includes(this.authorizationService.userRole)) {
			return true;
		} else {
			this.navigatorService.goBack();
			return false;
		}
	}
}
