import { Component, forwardRef, Type, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextBoxComponent } from "../text-box/text-box.component";

export type NumberType = "int" | "float";

@Component({
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<NumberBoxComponent<string>> => NumberBoxComponent)
	}],
	selector: "app-number-box",
	template: `<input type="text" [ngClass]="{disabled_color: disabledColor}" placeholder="{{placeholder}}" [value]="value" [attr.maxlength]="maxLength" [attr.disabled]="disabled" (input)="inputValidator($event)">`,
	styleUrls: ["./number-box.component.scss", "../controls.scss"]
})

export class NumberBoxComponent<T = string> extends TextBoxComponent<T> {
	@Input() public type: NumberType = "int";
	@Input() public wholeMaxLength?: number;
	@Input() public decimalMaxLength?: number;
	@Input() public withEmptyNull = true;

	public isDotRemoved = true;
	public value = "";
	private previousValue = "";

	public inputValidator(event: any): void {
		this.previousValue = this.value;
		if (this.type === "float") {
			this.inputValidatorFloat(event);
		} else {
			this.inputValidatorInt(event);
		}
	}

	public inputValidatorInt(event: any): void {
		const pattern = /^[0-9]*$/;
		if (!pattern.test(event.target.value)) {
			event.target.value = this.previousValue;
		} else {
			this.previousValue = event.target.value;
		}
		this.writeValue(event.target.value);
		if (this.onChange) {
			this.onChange(event.target.value);
		}
		if (this.onTouched) {
			this.onTouched();
		}
	}

	public inputValidatorFloat(event: any): void {
		const withDot = event.target.value.indexOf(".") !== -1;
		if (withDot && this.isDotRemoved && this.maxLength) {
			this.maxLength++;
			this.isDotRemoved = false;
		} else if (!withDot && !this.isDotRemoved && this.maxLength) {
			this.maxLength--;
			this.isDotRemoved = true;
		}
		const wordPattern = /[^\d.]/g;
		if (wordPattern.test(event.target.value)) {
			event.target.value = this.previousValue;
			return;
		}
		if (this.wholeMaxLength && this.decimalMaxLength && this.maxLength) {
			const decimalPattern = new RegExp(`^\\d{0,${this.wholeMaxLength}}\\.{1}\\d{0,${this.decimalMaxLength}}$`);
			const pattern = /^[0-9.]*$/;

			if (event.target.value.length <= this.maxLength && event.target.value.indexOf(".") === -1) {
				if (!pattern.test(event.target.value)) {
					event.target.value = this.previousValue;
				}

				this.writeValue(event.target.value);
				if (this.onChange) {
					this.onChange(event.target.value);
				}
				if (this.onTouched) {
					this.onTouched();
				}

				this.previousValue = event.target.value;
				return;
			}

			if (event.target.value === ".") {
				event.target.value = "0" + event.target.value.slice(0, event.target.value.length);
			}

			if (!decimalPattern.test(event.target.value)) {
				event.target.value = event.target.value.slice(0, event.target.value.length - 1);
			}

			if (event.target.value === "" && this.withEmptyNull) {
				event.target.value = null;
			}
		}
		this.previousValue = event.target.value;
		this.writeValue(event.target.value);
		if (this.onChange) {
			this.onChange(event.target.value);
		}
		if (this.onTouched) {
			this.onTouched();
		}
	}
}
