import { NgModule } from "@angular/core";
import { RouterModule as AngularRouterModule, Routes, UrlMatchResult, UrlSegment } from "@angular/router";
import { AuthorizationGuard } from "@core/guards/authorization.guard";
import { LogoutGuard } from "@core/guards/logout.guard";
import { environment } from "../../environments/environment";
import { AppComponent } from "../app.component";

export function isIdentityModule(urlSegment: UrlSegment[]): boolean {
	return !!urlSegment.length && (urlSegment[0].path === "login" || urlSegment[0].path === "about" || urlSegment[0].path === "restore-password");
}

export function matcher(urlSegment: UrlSegment[]): UrlMatchResult {
	return isIdentityModule(urlSegment) ? { consumed: [] } : null;
}

export const routes: Routes = [
	{
		path: "registration",
		loadChildren: () => import("../registration/registration.module").then((e) => e.RegistrationModule)
	},
	{
		matcher,
		loadChildren: () => {
			if (environment.project === "vesta-front") {
				return import("../identity/identity.module").then((e) => e.IdentityModule);
			} else {
				return import("../identity-nkfo/identity-nkfo.module").then((e) => e.IdentityNkfoModule);
			}
		}
	},
	{
		path: "",
		canActivate: [
			AuthorizationGuard
		],
		loadChildren: () => import("../layout/layout.module").then((e) => e.LayoutModule)
	},
	{
		path: "logout",
		canActivate: [
			LogoutGuard
		],
		component: AppComponent
	}
];

@NgModule({
	exports: [
		AngularRouterModule
	],
	imports: [
		AngularRouterModule.forRoot(routes)
	]
})
export class RouterModule { }
