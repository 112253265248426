export interface UserRole {
	id: number;
	name: string;
	value: string | null;
}

export class UserRoleConst {
	public static readonly ADMIN_SUPERADMIN: UserRole = {
		id: 1,
		name: "Супер администратор",
		value: "ADMIN_SUPERADMIN"
	};

	public static readonly ADMIN_ADMIN: UserRole = {
		id: 2,
		name: "Администратор",
		value: "ADMIN_ADMIN"
	};

	public static readonly CABINET_SUPERUSER: UserRole = {
		id: 3,
		name: "Пользователь ПУ-1",
		value: "CABINET_SUPERUSER"
	};

	public static readonly CABINET_USER: UserRole = {
		id: 4,
		name: "Пользователь ПУ-2",
		value: "CABINET_USER"
	};

	public static readonly CABINET_POLL_MANUAL_ENTRY_USER: UserRole = {
		id: 5,
		name: "Пользователь ПУ-3",
		value: "CABINET_POLL_MANUAL_ENTRY_USER"
	};
}
