import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
	selector: "[appClickOutside]"
})
export class ClickOutsideDirective {
	@Output("appClickOutside") public clickOutside = new EventEmitter<MouseEvent>();

	constructor(private readonly elementRef: ElementRef) { }

	@HostListener("document:click", ["$event.target"])
	public onMouseEnter(targetElement) {
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.clickOutside.emit(null);
		}
	}
}
