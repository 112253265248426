<header>
	<app-icon [class.disabled]="isDisabled" (click)="switchMonth(date, false);$event.stopPropagation();" class="left-icon">
		keyboard_arrow_left</app-icon>
	<div class="button" (click)="changePicker.emit(value);$event.stopPropagation();">
		{{capitalize(date | date:'LLLL, yyyy')}}</div>
	<app-icon [class.disabled]="isDisabled" (click)="switchMonth(date, true);$event.stopPropagation();" class="right-icon">
		keyboard_arrow_right</app-icon>
</header>
<ul>
	<li *ngFor="let day of daysOfWeek;index as i">{{day}}</li>
</ul>
<app-grid-drawer [columns]="7" [rows]="6" [items]="days">
	<ng-template #itemTemplate let-item>
		<div class="grid-item" [class.outside]="item.isOutside" [class.today]="isToday(item.date)" (click)="setDay(item.date)" [class.selected]="isSelected(item.date)"
			[class.disabled]="disabled(item.date)">
			{{item.date.getDate()}}</div>
	</ng-template>
</app-grid-drawer>
