<div
  [ngClass]="{
		'error': type === 'error',
		'success': type === 'success',
		'init': type === 'init',
    'init-wide': type === 'init-wide',
	}">
  <app-icon *ngIf="type === 'success'">check_circle_outline</app-icon>
  <app-icon *ngIf="type === 'error'">error_outline</app-icon>
  <app-icon *ngIf="type === 'init'"></app-icon>
  <label
    *ngIf="!messageArr.length"
    i18n
    >{{ message }}</label
  >
  <label
    *ngIf="messageArr.length"
    i18n>
    <p
      class="article"
      *ngFor="let messageUnit of messageArr">
      {{ messageUnit }}
    </p>
  </label>
  <app-icon (click)="close$$.next()">close</app-icon>
</div>
